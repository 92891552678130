import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/User/user.service';

@Component({
  selector: 'app-login-without-captcha',
  templateUrl: './login-without-captcha.component.html',
  styleUrls: ['./login-without-captcha.component.css']
})
export class LoginWithoutCaptchaComponent implements OnInit {

  
  user: any = {};
  loggedIn = false;
  token: string | undefined;
  productAccess: any = "";
  ProductAccess: any = [];
  UserAccessMaster: any = {};
  dbVerifiedUser: any = {};
  userRequest: any = {};

  constructor(private router: Router, private userService: UserService) { this.token = undefined; }


  ngOnInit(): void {
    // this.loggedIn = this.userService.checkLoginStatus();
    console.log("login");
  }


  hide: boolean = true;

  myFunction() {
    this.hide = !this.hide;
  }

  loginUser() {
    console.log(environment.baseURL);

    // if (this.token != undefined) {

    // } else {
    //   Swal.fire({
    //     title: "Login failed",
    //     text: "Please check captcha to proceed ",
    //     icon: 'error'
    //   });
    // }
    this.user.appId = [];
    this.user.appId.push("EDIV2Web");
    this.userRequest={
      user:this.user,
      appId:this.user.appId
    }

    this.userService.loginUser(this.userRequest).subscribe((result: any) => {
      if (result != false) {
        this.dbVerifiedUser = JSON.parse(result.data);

        if (this.dbVerifiedUser) {
          this.router.navigateByUrl('/home');
        }
      }
    });



  }

  resetPassword() {

  }

}
