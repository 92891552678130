<div class="dialog-headline">
    <mat-label style="float:left;">Schedule Timer</mat-label>
</div>

<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <mat-tab-group id="schedulerTab" color="primary" mat-stretch-tabs [selectedIndex]="selectedIndex"
        (selectedTabChange)="onTabChanged($event);" animationDuration="0ms" onch>
        <mat-tab label="Minutes">
            <div fxLayout="row wrap" style="margin-top: 10px;">
                <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100"
                    fxFlex="100">
                    Every
                </div>
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <mat-label>Minute(s)</mat-label>
                        <mat-select name="MinTabMinutes" [(ngModel)]="this.minTabMinValue">
                            <mat-option *ngFor="let minute of minutesArray" [value]="minute">
                                {{ minute}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Hourly">
            <div fxLayout="row wrap" style="margin-top: 10px;">
                <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100"
                    fxFlex="100">
                    Every
                </div>
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <mat-label>Hour(s)</mat-label>
                        <mat-select name="vendorCode" [(ngModel)]="this.hourTabHourValue">
                            <mat-option *ngFor="let eachVendor of hoursArray" [value]="eachVendor">
                                {{ eachVendor}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Daily">
            <div fxLayout="row wrap" style="margin-top: 10px;">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">
                        Every
                    </div>
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <mat-label>Day(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.dailyTabDayValue">
                                <mat-option *ngFor="let eachVendor of daysArray" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">
                        Starts at
                    </div>
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <mat-label>Hour(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.dailyTabHourValue">
                                <mat-option *ngFor="let eachVendor of hoursFrom0Array" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="padding-left: 10px;" fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100"
                        fxFlex="100">

                        <mat-form-field style="padding-left:10px ;">
                            <mat-label> Minute(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.dailyTabMinValue">
                                <mat-option *ngFor="let eachVendor of minutesFrom0Array" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Weekly">
            <div fxLayout="row wrap" style="margin-top: 10px;">
                <div style="padding-left: 10px;margin-top: 10px;" fxFlex.gt-lg="100" fxFlex.gt-md="100"
                    fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-checkbox id="SUN" [(ngModel)]="isSunChecked" name="SUN">Sunday
                        </mat-checkbox>
                    </div>
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-checkbox id="MON" [(ngModel)]="isMonChecked" name="MON">Monday
                        </mat-checkbox>
                    </div>
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">

                        <mat-checkbox id="TUE" [(ngModel)]="isTueChecked" name="TUE">Tuesday
                        </mat-checkbox>
                    </div>
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-checkbox id="WED" [(ngModel)]="isWedChecked" name="WED">Wednesday
                        </mat-checkbox>
                    </div>
                </div>
                <div style="padding-left: 10px;margin-top: 10px;" fxFlex.gt-lg="100" fxFlex.gt-md="100"
                    fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-checkbox id="THU" [(ngModel)]="isThuChecked" name="THU">Thursday
                        </mat-checkbox>
                    </div>
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-checkbox id="FRI" [(ngModel)]="isFriChecked" name="FRI">Friday
                        </mat-checkbox>
                    </div>
                    <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-checkbox id="SAT" [(ngModel)]="isSatChecked" name="SAT">Sturday
                        </mat-checkbox>
                    </div>
                </div>
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">
                        Starts at
                    </div>
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <mat-label>Hour(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.weeklyTabHourValue">
                                <mat-option *ngFor="let eachVendor of hoursFrom0Array" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="padding-left: 10px;" fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100"
                        fxFlex="100">

                        <mat-form-field style="padding-left:10px ;">
                            <mat-label> Minute(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.weeklyTabMinValue">
                                <mat-option *ngFor="let eachVendor of minutesFrom0Array" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Monthly">
            <div fxLayout="row wrap" style="margin-top: 10px;">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">

                    </div>
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <mat-label>Day(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.monthlyTabDayValue">
                                <mat-option *ngFor="let eachVendor of daysArray" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="margin-top:15px; padding-left: 25px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">
                        of every
                    </div>
                    <div style="padding-left: 10px;" fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100"
                        fxFlex="100">
                        <mat-form-field>
                            <mat-label>Month(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.monthlyTabMonthValue">
                                <mat-option *ngFor="let eachVendor of monthsNumberArray" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div style="margin-top:15px; padding-left: 10px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">
                        Starts at
                    </div>
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <mat-label>Hour(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.monthlyTabHourValue">
                                <mat-option *ngFor="let eachVendor of hoursFrom0Array" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="margin-top:15px; padding-left: 25px;" fxFlex.gt-lg="10" fxFlex.gt-md="10"
                        fxFlex.gt-xs="100" fxFlex="100">

                    </div>
                    <div style="padding-left: 10px;" fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100"
                        fxFlex="100">

                        <mat-form-field>
                            <mat-label> Minute(s)</mat-label>
                            <mat-select name="vendorCode" [(ngModel)]="this.monthlyTabMinValue">
                                <mat-option *ngFor="let eachVendor of minutesFrom0Array" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>

        <!-- <mat-tab label="Yearly">
            <div fxLayout="row wrap" style="margin-top: 10px; padding-left: 10px;">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field style="margin-top: 10px;">
                            <mat-label>Hour(s)</mat-label>
                            <mat-select name="vendorCode">
                                <mat-option *ngFor="let eachVendor of hoursArray" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>


                    </div>

                    <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100"
                        style="padding-left: 10px;">

                        <mat-form-field style="padding-left: 10px;margin-top: 10px;">
                            <mat-label> Minute(s)</mat-label>
                            <mat-select name="vendorCode">
                                <mat-option *ngFor="let eachVendor of minutesArray" [value]="eachVendor">
                                    {{ eachVendor}}
                                </mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab> -->
    </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions align="end">

    <button mat-stroked-button mat-dialog-close class="secondary">
        <mat-icon>close</mat-icon>Close
    </button>

    <!-- <button mat-stroked-button class="secondary" *ngIf="selectedIndex > 0" (click)="previousStep()">
        <mat-icon>arrow_back</mat-icon>Back
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex!=maxNumberOfTabs" (click)="moveToNextTab()">Next
        <mat-icon>arrow_forward</mat-icon>
    </button> -->

    <button mat-flat-button color="primary" (click)="generateCronExpressionAndSave();">
        <mat-icon>mode_edit</mat-icon>Update
    </button>

</mat-dialog-actions>