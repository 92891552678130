import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
// import { PriceQualifier } from "../models/PriceQualifier";

export enum CallType {
  VENDOR_INFO = "VendorInformation",
  VENDOR_CONNECTION = "VendorConnection",
  VENDOR_FIELDS = "VendorFields"
}

export class constant {

  //http header variable
  public static httpClientHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  // Host/Ports
  
  //for localhost use this
  // public static baseURL: string = "http://localhost";
  // public static fileDownloadTag: string = ":9030/edi";
  // public static fileParseErrorTag: string = ":9030/edi";
  // public static userTag: string = ":9030/edi/auth";
  // public static roleTag: string = ":9030";
  // public static vendorTag: string = ":9030/edi";
  // public static storeTag: string = ":9030/edi";
  // public static storeConfigUserTag: string = ":9030";
  // public static vendorPriceUpdateTag: string = ":9030/edi";
  // public static vendorPollerTag: string = ":9030/edi";
  // public static vendorOrderTag: string = ":9030/edi";

  //for prod server
    public static baseURL: string = environment.baseURL;
   public static fileDownloadTag: string = "ediFileDownloadService";
   public static fileParseErrorTag: string = "ediFileStatusService";
   public static userTag: string = "ediUserService";
   public static roleTag: string = "ediRoleService";
   public static vendorTag: string = "ediVendorService";
   public static storeTag: string = "ediStoreService";
   public static vendorPriceUpdateTag: string = "edivendorpriceupdateservice";
   public static vendorPollerTag: string = "edivendorpollerservice";
   public static vendorOrderTag: string = "edivendororderservice";
   public static storeConfigUserTag: string = ":9030";


  //============API Urls===============

  public static getfileDownloadUrl: string = constant.baseURL + constant.fileDownloadTag + "/FileDownload/getFileDownloadData";
  public static getfileParseErrorUrl: string = constant.baseURL + constant.fileParseErrorTag + "/FileStatus/getFileParseErrorData";

  // user Urls
  public static userLoginUrl: string = constant.baseURL + this.userTag + "/User/AuthenticateByPseudoName";
  public static refreshToken: string = constant.baseURL + this.userTag + "/User/refresh-token";
  // public static userLoginUrl: string = constant.baseURL + constant.userTag + "/User/AuthenticateByPseudoName";
  public static getUsersUrl: string = constant.baseURL + constant.userTag + "/User/getUsers";
  public static insertUserUrl: string = constant.baseURL + constant.userTag + "/User/InsertUsers";
  public static updateUserUrl: string = constant.baseURL + constant.userTag + "/User/UpdateUsers";
  public static deleteUserUrl: string = constant.baseURL + constant.userTag + "/User/DeleteUsers";
  public static getUserByUserNameUrl: string = constant.baseURL + constant.userTag + "/User/getUserByUserName";
  public static changeUserPasswordUrl: string = constant.baseURL + constant.userTag + "/User/changeUserPassword";
  public static changeUserPasswordAtLoginUrl: string = constant.baseURL + constant.userTag + "/User/changeUserPasswordAtLogin";


  //store config user urls
  // public static getUserFromStoreConfigUrl: string = constant.baseURL + constant.storeConfigUserTag + "/User/getUsersByID";

  //role url
  public static getRoleUrl: string = constant.baseURL + constant.roleTag + "/Role/getActiveRoles";

  //vendor url
  public static createFullVendorUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/createFullMasterVendor";
  public static getMasterVendorUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getMasterVendor";
  public static getVendorUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getAllVendorsByNPI";
  public static getVendorWithContractUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getAllVendorsWithContract";
  public static saveVendorWithContractUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/SaveVendorWithContract";
  public static checkIfContractAlreadyExists: string = constant.baseURL + constant.vendorTag + "/Vendor/CheckIfContractAlreadyExists";
  public static deleteVendorWithContractUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/DeleteVendorContract";
  public static getMasterVendorConnAndFieldsUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getMasterVendorConnAndFields";
  public static getVendorConnAndFieldsUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getVendorConnAndFields";
  public static getFullActiveVendorsByCodeUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getFullActiveVendorsByCode";
  public static mapFullVendorWithNPINoUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/mapFullVendorWithNPINo";
  public static testVendorFtpConnectionUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/testVendorFtpConnection";
  public static deleteMasterVendorUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/deleteMasterVendor";
  public static updateMasterVendorUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/updateFullMasterVendor";
  public static updateVendorConnectionUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/updateVendorConnection";
  public static getFTPSchedulerUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/getFTPScheduler";
  public static saveFTPSchedulerUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/saveFTPScheduler";
  public static updateVendorInformationUrl: string = constant.baseURL + constant.vendorTag + "/Vendor/UpdateVendorInformation";

  //Store 
  public static getAllNPINoUrl: string = constant.baseURL + constant.storeTag + "/Store/getAllStores";

  //VendorPoller
  public static updateFilePollerCronScheduler: string = constant.baseURL + constant.vendorPollerTag + "/VendorPoller/updateCronScheduler";

  //VendorPriceUpdate
  public static updatePriceUpdateCronScheduler: string = constant.baseURL + constant.vendorPriceUpdateTag + "/VendorPriceUpdate/updateCronScheduler";

  //VendorOrder
  public static updateInvoiceCronScheduler: string = constant.baseURL + constant.vendorOrderTag + "/VendorOrder/updatePOResponse810CronScheduler";
  public static updateACKCronScheduler: string = constant.baseURL + constant.vendorOrderTag + "/VendorOrder/updatePOResponse855CronScheduler";
  public static updatePOCronScheduler: string = constant.baseURL + constant.vendorOrderTag + "/VendorOrder/updatePORequestCronScheduler";

  public static KEY_SUCCESS = 'SUCCESS';
  public static KEY_ERROR = 'ERROR';
  public static KEY_WARNING = 'WARNING';
  public static KEY_FAILURE = 'FAILURE';
  public static ALERT_ADDED_TITLE = 'Added';
  public static ALERT_WARNING_TITLE = 'Warning';
  public static ALERT_CREATE_TITLE = 'Created';
  public static ALERT_UPDATE_TITLE = 'Updated';
  public static ALERT_DELETE_TITLE = 'Deleted';
  public static ALERT_FAILED_TITLE = 'Failed';
  public static ALERT_SUCCESS_TITLE = 'Success';
  public static ALERT_TIMEOUT_TITLE = 'Time out';
  public static KEY_STATION_DETAILS = 'stationDetail';

  public static AWP = "AWP";
  public static CAT = "CAT";
  public static CON = "CON";
  public static DAP = "DAP";
  public static FUL = "FUL";
  public static MSR = "MSR";
  public static NET = "NET";
  public static PRO = "PRO";
  public static RES = "RES";
  public static UCP = "UCP";
  public static RTL = "RTL";
  public static RLT = "RLT";
  public static PRP = "PRP";
  public static INV = "INV";
  public static PBQ = "PBQ";
  public static BCH = "BCH";
  public static RESM = "RESM";

  public static qualifiers: string[] = ["AWP", "CAT", "CON", "DAP", "FUL", "MSR", "NET", "PRO", "RES", "UCP", "RTL", "RLT", "PRP", "INV", "PBQ", "BCH", "RESM"]
}
