<!-- card for header bar-->
<div class="row page-titles">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <h3 class="text-themecolor header-margin">Schedular</h3>
            <a style="color: #1e88e5" matTooltip="Go to home" href="Home">Home > </a><label>Scheduler</label>
        </div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"></div>


    </div>
</div>

<mat-card>
    <div class="example-container mat-elevation-z8">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
            style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">

            <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                <!-- Checkbox Column -->

                <ng-container color="primary" matColumnDef="IntervalType">
                    <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Scheduler Type </mat-header-cell>
                    <mat-cell color="primary" *matCellDef="let element"> {{element.IntervalType}} </mat-cell>
                </ng-container>

                <ng-container color="primary" matColumnDef="CronExpression">
                    <mat-header-cell color="primary" [fxHide]="true" *matHeaderCellDef mat-sort-header>Cron Expression
                    </mat-header-cell>
                    <mat-cell color="primary" [fxHide]="true" *matCellDef="let element"> {{element.CronExpression}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CronDescription">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Scheduler Interval</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.CronDescription}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="Edit {{element.IntervalType}}"
                            (click)="openCronDialog(element);" color="primary">
                            <mat-icon>mode_edit</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

            </mat-table>

            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
            </mat-paginator>

        </div>
    </div>

</mat-card>