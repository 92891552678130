import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileParseError } from 'src/app/models/FileParseError';
import { FileParseErrorRequest } from 'src/app/models/RequestModel/FileParseErrorRequest';
import { userAccess } from 'src/app/models/userAccess';
import { FileParsingErrorService } from 'src/app/services/FileParseError/file-parsing-error.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-file-parsing-error',
  templateUrl: './file-parsing-error.component.html',
  styleUrls: ['./file-parsing-error.component.css']
})
export class FileParsingErrorComponent implements OnInit {


  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['NPINo', 'vendorCode', 'contractName', 'fileName', 'fileType', 'errorDate', 'errorMessages'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<FileParseError> = new MatTableDataSource();

  //=========variable Declaration============
  fileParsingError: any = {};
  receivedFileResponse: any = {};

  resultData: any;
  fileParseErrorRequest: FileParseErrorRequest = new FileParseErrorRequest;
  userAccessData!: userAccess;
  currentDate!: any;

  constructor(private fileParseErrorService: FileParsingErrorService, private userService: UserService, private utilityService: UtilityService) {
    this.userAccessData = this.userService.getUsersAccessData();
    this.fileParsingError.NPINo = this.userAccessData.NPINo;
    this.fileParsingError.pseudoName = this.userAccessData.pseudoName;
  }



  ngOnInit(): void {
    this.currentDate = new Date();
    var previousMonth = new Date(this.currentDate);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    this.fileParsingError.fromDate = previousMonth;
    this.fileParsingError.toDate = new Date();
    //FETCHING THE FILE PARSING ERROR DATA ON PAGE VIEW INIT
    this.getFileParseErrorData();
  }

  onFromDateChange() {

    if ((Date.parse(this.fileParsingError.fromDate) > Date.parse(this.fileParsingError.toDate)) && this.fileParsingError.toDate) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than To date").subscribe(result => {
        this.fileParsingError.fromDate = "";
      });

    } else if (Date.parse(this.fileParsingError.fromDate) > Date.parse(this.currentDate)) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than current date").subscribe(result => {
        this.fileParsingError.fromDate = "";
      });
    }
  }

  onToDateChange() {


    if ((Date.parse(this.fileParsingError.toDate) < Date.parse(this.fileParsingError.fromDate)) && this.fileParsingError.fromDate) {
      this.utilityService.showAlert("WARNING", "Warning", "To date should be greater than From date").subscribe(result => {
        this.fileParsingError.toDate = "";
      });

    } else if (Date.parse(this.fileParsingError.toDate) > Date.parse(this.currentDate)) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select To date lower than current date").subscribe(result => {
        this.fileParsingError.toDate = "";
      });
    }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    this.getFileParseErrorData();
  }

  // ================GET THE FILE ERROR DATA BASED ON THE PAGINATION=================
  getFileParseErrorData() {

    // setting request object 
    this.fileParseErrorRequest.NPINo = this.userAccessData.NPINo;
    this.fileParseErrorRequest.fromDate = this.fileParsingError.fromDate;
    this.fileParseErrorRequest.toDate = this.fileParsingError.toDate;
    this.fileParseErrorRequest.fileType = this.fileParsingError.fileType;
    this.fileParseErrorRequest.pagination.pageNumber = this.currentPage + 1;
    this.fileParseErrorRequest.pagination.pageSize = this.pageSize;


    this.fileParseErrorService.getFileParseErrorData(this.fileParseErrorRequest).subscribe(response => {

      if (response.result == "SUCCESS") {
        this.receivedFileResponse = JSON.parse(response.data);

        this.dataSource = new MatTableDataSource<FileParseError>(this.receivedFileResponse.records);

        this.dataSource.paginator = this.paginator!;
        this.dataSource.sort = this.sort!;
        setTimeout(() => {

          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = this.receivedFileResponse.totalRecords;

        });

        if (this.receivedFileResponse.records.length < 1) {
          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "No data found"
          );
        }
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the file parse error data"
        );
      }

    });
  }

}
