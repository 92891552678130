<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<ngx-ui-loader fgsColor="#1e88e5" text="Please wait...!" pbColor="#0de33e" [pbThickness]="thickness" textColor="#1e88e5">

</ngx-ui-loader>


<div ngxUiLoaderBlurred [blur]="bluerValue">
    <!-- This page content will be blurred/frosted when foreground loader is showed -->
    <router-outlet>
    </router-outlet>
</div>