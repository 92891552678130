import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { userAccess } from '../models/userAccess';
import { UserService } from '../services/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  userAccessData!: userAccess;
  constructor(private routerService: Router, private userService: UserService) {
    this.userAccessData = this.userService.getUsersAccessData();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var rightAccess = JSON.parse(this.userAccessData.userRights);

    if (JSON.parse(localStorage.getItem('changePasswordAtLogin') || '')!= false) {
      this.routerService.navigateByUrl('/changePasswordAtLogin');

      return false;
    } else {
      return true;
    }

  }

}
