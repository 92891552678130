import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'src/app/ediComponents/user/changePassword/change-password.component';
import { UserModalComponent } from 'src/app/ediComponents/user/userModal/user-modal.component';
import { userAccess } from 'src/app/models/userAccess';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userAccessData!: userAccess;//this variable will hold user data

  constructor(private userService: UserService,  private dialogService: MatDialog, private httpClient: HttpClient, private utilityService: UtilityService) {

  }

  ngOnInit(): void {
    this.userAccessData = this.userService.getUsersAccessData();
  }

  logOutUser() {
    //clearing the session from the storage
    this.userService.logOutUser();
    
  }

  editLoggedinUserProfile() {

    let userData = {
      userName: this.userAccessData.userName
    };

    this.userService.getUserByUserName(userData).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedUserResponse = JSON.parse(response.data);

        this.dialogService.open(UserModalComponent, {
          width: '50%',
          disableClose: true,
          data: { userData: receivedUserResponse, headerTitle: "Update User" }
        });

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the user data"
        );
      }

    });


  }

  forgotPassword() {

    this.dialogService.open(ChangePasswordComponent, {
      disableClose: true,
      data: { NPINo: this.userAccessData.NPINo,userName:this.userAccessData.userName, headerTitle: "Change Password" }
    });
  }

}
