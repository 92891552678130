<!-- card for header bar-->
<div class="row page-titles">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <h3 class="text-themecolor header-margin">Vendor Master</h3>
            <a style="color:#1e88e5 ;" matTooltip="Go to home" href="Home">Home > </a><label>Vendor Master</label>

        </div>

        <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"
            style="padding-top: 10px;padding-right: 10px;">
            <button mat-flat-button color="primary" (click)="openVendorMasterCreateDialog();" cdkFocusInitial
                style="float: right; ">
                <mat-icon>add</mat-icon> Vendor
            </button>
        </div>

    </div>

</div>
<br>



<!-- card for search bar-->
<!-- ============================================================== -->
<div fxLayout="row" fxLayout.xs="column">

    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card fxLayoutGap="30px">

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field style="padding-left:10px ;">
                    <input matInput placeholder="Short Name" name="PseudoName" #PseudoName="ngModel" id="PseudoName"
                        [(ngModel)]="vendorMaster.pseudoName" readonly>
                </mat-form-field>

            </div>



            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <mat-label>Select Vendor</mat-label>
                    <mat-select #vendorCode="ngModel" name="vendorCode" [(ngModel)]="vendorMaster.vendorCode">
                        <mat-option *ngFor="let eachVendor of vendorMasterArray" [value]="eachVendor.vendorCode">
                            {{ eachVendor.vendorCode}}
                        </mat-option>

                    </mat-select>
                </mat-form-field>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                style="padding-top: 10px;padding-right: 10px;">
                <button mat-flat-button color="primary" cdkFocusInitial style="float: right;"
                    (click)="getMasterVendor()">
                    <mat-icon>search</mat-icon>Search
                </button>
            </div>

        </mat-card>
    </div>

</div>


<!-- TABLE FOR SHOWING THE DATA  -->

<div class="example-container mat-elevation-z8">

    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
        style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">
        <mat-card>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                        style="padding-top: 10px;padding-right: 10px;">
                        <button mat-raised-button matTooltip="Export to excel file"
                            (click)="exporter.exportTable('xlsx', {fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                            <mat-icon>description</mat-icon>Excel
                        </button>
                        <button mat-raised-button matTooltip="Export to csv file"
                            (click)="exporter.exportTable('csv',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                            <mat-icon>description</mat-icon>Csv
                        </button>
                        <button mat-raised-button matTooltip="Export to json file"
                            (click)="exporter.exportTable('json',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                            <mat-icon>description</mat-icon>Json
                        </button>
                        <button mat-raised-button matTooltip="Export to txt file"
                            (click)="exporter.exportTable('txt',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                            <mat-icon>description</mat-icon>Txt
                        </button>
                    </div>
        
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>
        
                    <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                        style="padding-top: 10px;padding-right: 10px;">
                        <mat-form-field floatLabel="never" style="padding-right: 5px;float: right;">
                            <input matInput [(ngModel)]="searchKey" (keyup)="applyFilter()" placeholder="Search"
                                autocomplete="off">
                            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey"
                                (click)="onSearchClear()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
            </div>

        </mat-card>
        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

            <!-- Checkbox Column -->

            <ng-container color="primary" matColumnDef="vendorCode">
                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Vendor Code </mat-header-cell>
                <mat-cell color="primary" *matCellDef="let element"> {{element.vendorCode}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="vendorName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Vendor Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.vendorName}} </mat-cell>
            </ng-container>


            <ng-container matColumnDef="address1">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Address1</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.address1}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="city">
                <mat-header-cell *matHeaderCellDef mat-sort-header> City</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef mat-sort-header> State</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="zip">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Zip</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.zip}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Is Active?</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.isActive}} </mat-cell>
            </ng-container>


            <ng-container matColumnDef="Action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button matTooltip="Edit {{element.vendorCode}}" (click)="openVendorMasterUpdateDialog(element);" color="primary">
                        <mat-icon>mode_edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete {{element.vendorCode}}" color="warn" (click)="deleteVendorMaster(element)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

        </mat-table>

        <!-- <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5,10,20]" showFirstLastButtons>
      </mat-paginator> -->

        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
        </mat-paginator>

    </div>
</div>