import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { constant } from 'src/app/constant/constant';
import { UserRequest } from 'src/app/models/RequestModel/UserRequest';
import { User } from 'src/app/models/User';
import { userAccess } from 'src/app/models/userAccess';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UserModalComponent } from '../userModal/user-modal.component';

interface promptModel {
  promptMessage: string;
  promptTitle: string;
}


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})


export class UserComponent implements OnInit {

  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['NPINo', 'userName', 'lName', 'noOfAttempt', 'isLocked', 'isActive', 'Action'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<User> = new MatTableDataSource();


  UserAccessMaster: any = {};
  passUserData!: User;
  user: any = {};
  promptData!: promptModel;
  alertData!: any;


  userArray!: User[];
  userRequest: UserRequest = new UserRequest;
  userAccessData!: userAccess;
  resultData!: any;


  constructor(private userService: UserService, private utilityService: UtilityService, private dialogService: MatDialog) {
    this.userAccessData = userService.getUsersAccessData();
    this.user.NPINo = this.userAccessData.NPINo;
    this.user.pseudoName = this.userAccessData.pseudoName;
  }

  ngOnInit(): void {

    //getting users on load for showing in the table
    this.getUsersOnLoad();

    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }
  //==========getting the user ===============
  getUsersOnLoad() {


    // setting request object 
    this.userRequest.NPINo = this.userAccessData.NPINo;
    this.userRequest.pagination.pageNumber = this.currentPage + 1;
    this.userRequest.pagination.pageSize = this.pageSize;

    this.userService.getUsers(this.userRequest).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedUserResponse = JSON.parse(response.data);

        this.dataSource = new MatTableDataSource<User>(receivedUserResponse.records);

        this.dataSource.paginator = this.paginator!;
        this.dataSource.sort = this.sort!;

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the user data"
        );
      }

    });
  }



  openUserCreateModal() {

    const userCreateDialogRef = this.dialogService.open(UserModalComponent, {
      width: '50%',
      disableClose: true,
      data: { headerTitle: "Create User" }
    });

    userCreateDialogRef.afterClosed().subscribe(result => {
      //getting users on load for showing in the table after creating the user
      if(result==true){
      this.getUsersOnLoad();
      }
    });

  }


  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }


  getUsers() {

    // setting request object 
    this.userRequest.NPINo = this.userAccessData.NPINo;
    this.userRequest.userName = this.user.userName;
    this.userRequest.pagination.pageNumber = this.currentPage + 1;
    this.userRequest.pagination.pageSize = this.pageSize;

    this.userService.getUsers(this.userRequest).subscribe(response => {

      if (response.result == "SUCCESS") {

        this.resultData = JSON.parse(response.data);
        this.dataSource = new MatTableDataSource<User>(this.resultData.records);

        this.dataSource.paginator = this.paginator!;
        this.dataSource.sort = this.sort!;

        setTimeout(() => {

          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = this.resultData.totalRecords;

        });

        if (this.resultData.records.length < 1)
          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "No data found for the user name :" + this.user.userName
          );

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the user data"
        );
      }

    });

  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    if (event.pageIndex != event.previousPageIndex)
      this.getUsers();


  }

  openUpdateUserModal(receivedUser: User) {

    console.log(receivedUser);

    const userUpdateDialogRef = this.dialogService.open(UserModalComponent, {
      width: '50%',
      disableClose: true,
      data: { userData: receivedUser, headerTitle: "Update User" }
    });

    userUpdateDialogRef.afterClosed().subscribe(result => {

      if(result==true)
      //getting users on load for showing in the table after updating the user
      this.getUsersOnLoad();
    });
  }

  deleteUser(receivedUser: User) {
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {

      if (result == true) {
        this.userService.deleteUsers(receivedUser).subscribe(response => {

          if (response.result == "SUCCESS") {

            this.utilityService.showAlert(
              "SUCCESS",
              "Deleted",
              "User deleted successfully"
            ).subscribe(result => {
              if (result == true)
                this.dialogService.closeAll()
              //getting users on load for showing in the table after deleting the user
              this.getUsersOnLoad();
            });

          }
          else {

            this.utilityService.showAlert(
              "ERROR",
              "Failed",
              "Error occurred while deleting the user data"
            );

          }

        });
      }
    });

  }

}
