<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div class="dialog-headline">
    <mat-label style="float:left;">Map Vendor</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<!-- <h2 mat-dialog-title style="background-color: rgb(38, 142, 226);">Install Angular</h2> -->
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <!-- user form taking values -->
    <mat-tab-group color="primary" mat-stretch-tabs [selectedIndex]="selectedIndex" animationDuration="0ms">
        <mat-tab disabled label="Vendor Information">

            <app-vendor-information [receivedVendorInfo]="dbVendorInfoData" [isFromMapping]="true" [isUpdate]="isUpdate"
                [isCreate]="isCreate" (getFullMasterVendor)="getFullMasterVendor($event)" [disableFields]="true"
                #vendorInformation>
            </app-vendor-information>

        </mat-tab>

        <mat-tab disabled label="Vendor Connection">

            <app-vendor-connection [receivedVendorConn]="dbVendorConnection" [disableFields]="true" #vendorConnection>
            </app-vendor-connection>

        </mat-tab>

        <mat-tab disabled label="Vendor Fields">

            <app-vendor-fields [receivedVendorFields]="dbVendorFields" [disableFields]="true" #vendorFields>
            </app-vendor-fields>

        </mat-tab>

    </mat-tab-group>

</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end">

    <button mat-stroked-button mat-dialog-close class="secondary">
        <mat-icon>close</mat-icon>Close
    </button>

    <button mat-stroked-button class="secondary" *ngIf="selectedIndex > 0" (click)="previousStep()">
        <mat-icon>arrow_back</mat-icon>Back
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex!=maxNumberOfTabs" (click)="moveToNextTab()">Next
        <mat-icon>arrow_forward</mat-icon>
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex==2 && !isUpdate" (click)="mapFullVendorWithNPI();">
        Map with NPINo
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex==0 && isUpdate" (click)="updateVendorInformation()">
        <mat-icon matTooltip="Update vendor information">mode_edit</mat-icon>Update
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex==1 && isUpdate" (click)="updateVendorConnection()">
        <mat-icon matTooltip="Update vendor connection">mode_edit</mat-icon>Update
    </button>

</mat-dialog-actions>