import { Injectable } from '@angular/core';
import { userAccess } from 'src/app/models/userAccess';
import { UserService } from 'src/app/services/User/user.service';

export interface Menu {
  state: string;
  name: string;
  type: string;
}



@Injectable()
export class MenuItems {

  userAccessData!: userAccess;
  constructor(private userService: UserService) {
    
  }

  retPageMenu: Menu[] = [];

  getMenuitem(): Menu[] {


    return this.retPageMenu;
  }

  setMenuitem(): Menu[] {


    this.userAccessData = this.userService.getUsersAccessData();
    this.userAccessData.userRights = JSON.parse(this.userAccessData.userRights);
    
    this.retPageMenu.length = 0;

    if (this.userAccessData.userRights?.webRights.webUser != null && (this.userAccessData.userRights?.webRights.webUser.add == true || this.userAccessData.userRights?.webRights.webUser.update == true || this.userAccessData.userRights?.webRights.webUser.delete == true || this.userAccessData.userRights?.webRights.webUser.search == true)) {

      let userMenu = {} as Menu;
      userMenu.name = 'User';
      userMenu.state = 'user';
      userMenu.type = 'link';
      this.retPageMenu.push(userMenu);
    }

    if (this.userAccessData.userRights?.webRights.fileDownload != false) {


      let fileDownloadMenu = {} as Menu;
      fileDownloadMenu.name = 'File Download';
      fileDownloadMenu.state = 'fileDownload';
      fileDownloadMenu.type = 'link';
      this.retPageMenu.push(fileDownloadMenu);
    }

    if (this.userAccessData.userRights?.webRights.fileParsingError != false) {


      let fileParsingMenu = {} as Menu;
      fileParsingMenu.name = 'File Parsing Error';
      fileParsingMenu.state = 'fileParsingError';
      fileParsingMenu.type = 'link';
      this.retPageMenu.push(fileParsingMenu);
    }

    if (this.userAccessData.userRights?.webRights.webVendorMapping != null && (this.userAccessData.userRights?.webRights.webVendorMapping.add == true || this.userAccessData.userRights?.webRights.webVendorMapping.update == true || this.userAccessData.userRights?.webVendorMapping.webUser.delete == true || this.userAccessData.userRights?.webRights.webVendorMapping.search == true)) {


      let vendorMappingMenu = {} as Menu;
      vendorMappingMenu.name = 'Vendor Mapping';
      vendorMappingMenu.state = 'vendorMapping';
      vendorMappingMenu.type = 'link';
      this.retPageMenu.push(vendorMappingMenu);
    }

    if (this.userAccessData.userRights?.webRights.webVendorMaster != null && (this.userAccessData.userRights?.webRights.webVendorMaster.add == true || this.userAccessData.userRights?.webRights.webVendorMaster.update == true || this.userAccessData.userRights?.webRights.webVendorMaster.delete == true || this.userAccessData.userRights?.webRights.webVendorMaster.search == true)) {

      let vendorMasterMenu = {} as Menu;
      vendorMasterMenu.name = 'Vendor Master';
      vendorMasterMenu.state = 'vendorMaster';
      vendorMasterMenu.type = 'link';
      this.retPageMenu.push(vendorMasterMenu);
    }

    if (this.userAccessData.userRights?.webRights.webVendorMaster != null && (this.userAccessData.userRights?.webRights.webVendorMaster.add == true || this.userAccessData.userRights?.webRights.webVendorMaster.update == true || this.userAccessData.userRights?.webRights.webVendorMaster.delete == true || this.userAccessData.userRights?.webRights.webVendorMaster.search == true)) {

      let schedulerMenu = {} as Menu;
      schedulerMenu.name = 'Scheduler Configuration';
      schedulerMenu.state = 'scheduler';
      schedulerMenu.type = 'link';
      this.retPageMenu.push(schedulerMenu);
    }



    return this.retPageMenu;
  }
}
