import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
    providedIn: 'root'
})
export class VendorPollerService {

    constructor(private httpClient: HttpClient) { }

    UpdateCronScheduler(cronExpression: any) {

        return this.httpClient.post<any>(constant.updateFilePollerCronScheduler, cronExpression, constant.httpClientHeader).pipe(
          map((response: any) => {
            return response;
          })
        )
      }
}