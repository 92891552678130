import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordAtLoginComponent } from './changePasswordAtLogin/change-password-at-login.component';
import { ChangePasswordAtLoginGuard } from './guards/change-password-at-login.guard';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { authInterceptorProviders } from './services/utility/http-auth-interceptor.service';
import { PageNotFoundComponent } from './utility/pageNotFound/page-not-found.component';
import { UnAuthorisePageComponent } from './utility/unAuthorisePage/un-authorise-page.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'loginWithoutCaptcha', component: LoginWithoutCaptchaComponent },
  { path: 'changePasswordAtLogin', component: ChangePasswordAtLoginComponent },
  { path: 'pageNotFound', component: PageNotFoundComponent },
  // { path: '**',redirectTo:'/pageNotFound'},
  { path: 'unAuthorisedPage', component: UnAuthorisePageComponent },
  { path: '', component: LoginComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [

      {
        path: '',
        loadChildren: () => import('./ediComponents/edi-components.module').then(m => m.EdiComponentsModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  exports: [RouterModule],
  providers: [authInterceptorProviders]
})
export class AppRoutingModule { }
