import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { StoreGeneralConfig } from 'src/app/models/StoreGeneralConfig';
import { MatTableDataSource } from '@angular/material/table';
import { errorMonitor } from 'events';
import { MatDialog } from '@angular/material/dialog';
import { CronDialogComponent } from './cron-dialog/cron-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit {
  message: String = '';
  displayedColumns = ['IntervalType', 'CronExpression', 'CronDescription', 'Action'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<StoreGeneralConfig> = new MatTableDataSource();


  storegeneralconfig: any = {};

  public schdulerForm: FormGroup = new FormGroup({});

  constructor(private vendorService: VendorService, private utilityService: UtilityService, public dialogService: MatDialog) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getScheduler();

  }
  initForm(): void {
    this.schdulerForm = new FormGroup({
    });
  }

  getScheduler() {
    this.vendorService.getFTPScheduler().subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedResponse = JSON.parse(response.data);

        this.dataSource = new MatTableDataSource<StoreGeneralConfig>(receivedResponse);

        this.dataSource.paginator = this.paginator!;
        this.dataSource.sort = this.sort!;
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching vendor data"
        );
      }

    });
  }

  openCronDialog(element: any) {

    const mapDialogRef = this.dialogService.open(CronDialogComponent, {
      width: '60%',
      height: '35%',
      disableClose: true,
      data: {
        receivedCronExpression: element.CronExpression,
        intervalType:element.IntervalType
      }
    });

    mapDialogRef.afterClosed().subscribe(result => {
      // NOT USING THE RESULT BECAUSE IF THE CLICKS ON BACKGROUND THEN RESULT IS UNDEFINED 
      // for loading the vendors on page  load
      if (result == true) {

        this.getScheduler();
      }
    });
  }
}
