import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public reportedError!: boolean;
  public errorPercentage: number = 100;
  public timer: any;
  public colorValue: string="primary";

  public checkChanged = (event: any) => {
    this.reportedError = event.checked;
    this.reportedError ? this.startTimer() : this.stopTimer();
  }
  private startTimer = () => {
    this.timer = setInterval(() => {
      this.errorPercentage -= 5;


      if (this.errorPercentage === 65) {
        this.colorValue="accent";
      }
      if (this.errorPercentage === 35) {
        this.colorValue="warn";
      }
      
      if (this.errorPercentage === 0) {
        clearInterval(this.timer);
        this.colorValue="primary";
      }
    }, 1000);}

    private stopTimer = () => {
      clearInterval(this.timer);
      this.colorValue="primary";
      this.errorPercentage = 100;
    }
  

}
