import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { userAccess } from 'src/app/models/userAccess';
import { RoleService } from 'src/app/services/Role/role.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit {

  user: any = {};
  roleArray: any[] = [];
  hide: boolean = true;
  hideConfirm: boolean = true;
  showDetails: boolean = true;
  showError: boolean = false;
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  deleteUserData: boolean = false;
  pseudoName: any = "";//used for showing in the modal form 
  NPINo: any = "";//used for showing in the modal form 

  modal_title!: string;
  headerTitle!: string;
  userAccessData!: userAccess;

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private dialogService: MatDialogRef<UserModalComponent>, private utilityService: UtilityService, private roleService: RoleService, private userService: UserService) {
    this.userAccessData = userService.getUsersAccessData();
    if (receivedData.userData) {
      this.user = receivedData.userData;
      this.user.confirmPassword = receivedData.userData.password;
    }
  }

  ngOnInit(): void {
    console.log(this.receivedData);
    this.pseudoName = localStorage.getItem("pseudoName");
    this.NPINo = localStorage.getItem("NPINo");
    this.user.NPINo = this.NPINo;
  }

  ngAfterViewInit() {
    //calling role api to load in role dropdown
    this.getRoles();
  }



  onPasswordStrengthChanged(event: any) {
    if (event == 100)
      this.isPasswordStrong = true;

  }

  //===========api for getting roles===========
  getRoles() {

    let roleFilter = {
      isActive: true
    };
    this.roleService.getRoles(roleFilter).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedUserResponse = JSON.parse(response.data);

        this.roleArray = receivedUserResponse;
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the role data"
        );
      }

    });
  }

  //===========api for creating user===========
  createUser() {

    this.user.userName = this.user.userName + "@" + this.pseudoName;
    this.userService.createUsers(this.user).subscribe(response => {

      if (response.result == "SUCCESS") {

        this.utilityService.showAlert(
          "SUCCESS",
          "Created",
          "User created successfully"
        ).subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.dialogService.close(true);
        });

      } else if (response.result == "ERROR") {

        var error = JSON.parse(response.data);

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          error
        ).subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
        });
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while creating the user data"
        );

      }

    });
  }

  //===========api for udpating user===========
  updateUser() {
    this.user.modifiedBy = localStorage.getItem("userName");
    this.userService.updateUsers(this.user).subscribe(response => {

      if (response.result == "SUCCESS") {

        this.utilityService.showAlert(
          "SUCCESS",
          "Updated",
          "User updated successfully"
        ).subscribe(result => {
          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.dialogService.close(true)
        });

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while updating the user data"
        );
      }

    });
  }


  //===========api for deleting user===========
  deleteUser() {
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
        this.deleteUserData = true;
        this.userService.deleteUsers(this.user).subscribe(response => {

          if (response.result == "SUCCESS") {

            this.utilityService.showAlert(
              "SUCCESS",
              "Deleted",
              "User deleted successfully"
            ).subscribe(result => {

              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.close(true)
            });

          }
          else {

            this.utilityService.showAlert(
              "ERROR",
              "Failed",
              "Error occurred while deleting the user data"
            );

          }

        });
      }
    });
  }

  roleChange(event: any) {
    console.log(event)
    var countryObj = this.roleArray.find(stat => stat.roleName == event);

    this.user.roleId = countryObj.roleId;
    this.user.role = countryObj.roleName
    this.user.roleName = countryObj.roleName
  }
  AllowOnlyCharacter(event: any) {

    return this.utilityService.AllowOnlyCharacter(event);
  }

}
