<form #vendorFieldsForm="ngForm" autocomplete="off">

  <br>

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Vendor Code" name="vendorCode" #vendorCode="ngModel" id="vendorCode"
            [(ngModel)]="vendorFields.vendorCode" required disabled>

          <mat-error *ngIf="vendorCode.invalid && (vendorCode.dirty || vendorCode.touched)" class="invalid-feedback">
            Please enter vendor code
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="App Receiver Code" name="appReceiverCode" #appReceiverCode="ngModel"
            id="appReceiverCode" [(ngModel)]="vendorFields.appReceiverCode" required [disabled]="disableFields">
          <mat-error *ngIf="appReceiverCode.invalid && (appReceiverCode.dirty || appReceiverCode.touched)"
            class="invalid-feedback">
            Please enter App Receiver Code
          </mat-error>
        </mat-form-field>
      </div>
      
    </div>
  </div>



  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="Application" name="application" #application="ngModel" id="application"
            [(ngModel)]="vendorFields.application" required [disabled]="disableFields">



          <mat-error *ngIf="application.invalid && (application.dirty || application.touched)" class="invalid-feedback">
            Please enter application name
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Element Seperator" name="elementSeperator" #elementSeperator="ngModel"
            id="elementSeperator" [(ngModel)]="vendorFields.elementSeperator" required [disabled]="disableFields">



          <mat-error *ngIf="elementSeperator.invalid && (elementSeperator.dirty || elementSeperator.touched)"
            class="invalid-feedback">
            Please enter element seperator
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Sub Element Seperator" name="subElementSeperator" #subElementSeperator="ngModel"
            id="subElementSeperator" [(ngModel)]="vendorFields.subElementSeperator" required [disabled]="disableFields">



          <mat-error *ngIf="subElementSeperator.invalid && (subElementSeperator.dirty || subElementSeperator.touched)"
            class="invalid-feedback">
            Please enter sub element seperator
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="Segment Seperator" name="segmentSeperator" #segmentSeperator="ngModel"
            id="segmentSeperator" [(ngModel)]="vendorFields.segmentSeperator" required [disabled]="disableFields">



          <mat-error *ngIf="segmentSeperator.invalid && (segmentSeperator.dirty || segmentSeperator.touched)"
            class="invalid-feedback">
            Please enter segment seperator
          </mat-error>
        </mat-form-field>
      </div>


    </div>
  </div>

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="ID Code Qualifier By" name="idCodeQualifierBy" #idCodeQualifierBy="ngModel"
            id="idCodeQualifierBy" [(ngModel)]="vendorFields.idCodeQualifierBy" required [disabled]="disableFields">

        </mat-form-field>

      </div>
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="ISA Test Indicator" name="isaTestIndicator" #isaTestIndicator="ngModel"
            id="isaTestIndicator" [(ngModel)]="vendorFields.isaTestIndicator" [disabled]="disableFields">

        </mat-form-field>



      </div>
    </div>
  </div>

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="IEA Interchange Control No" name="ieaInterchangeControlNo"
            #ieaInterchangeControlNo="ngModel" id="ieaInterchangeControlNo"
            [(ngModel)]="vendorFields.ieaInterchangeControlNo" [disabled]="disableFields">

        </mat-form-field>

      </div>
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="ISA Acknowledgement Request" name="isaAcknowledgementRequest"
            #isaAcknowledgementRequest="ngModel" id="isaAcknowledgementRequest"
            [(ngModel)]="vendorFields.isaAcknowledgementRequest" [disabled]="disableFields">

        </mat-form-field>


      </div>
    </div>
  </div>

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="ISA ID Qualifier Receiver" name="isaIdQualifierReceiver"
            #isaIdQualifierReceiver="ngModel" id="isaIdQualifierReceiver"
            [(ngModel)]="vendorFields.isaIdQualifierReceiver" [disabled]="disableFields">

        </mat-form-field>

      </div>
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="ISA ID Qualifier Sender" name="isaIdQualifierSender"
            #isaIdQualifierSender="ngModel" id="isaIdQualifierSender" [(ngModel)]="vendorFields.isaIdQualifierSender"
            [disabled]="disableFields">

        </mat-form-field>


      </div>
    </div>
  </div>

  

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="ISA Interchange Control No" name="isaInterchangeCtrlNo"
            #isaInterchangeCtrlNo="ngModel" id="isaInterchangeCtrlNo" [(ngModel)]="vendorFields.isaInterchangeCtrlNo"
            [disabled]="disableFields">

        </mat-form-field>

      </div>
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="ISA Interchange Receiver Id" name="isaInterchangeReceiverId"
            #isaInterchangeReceiverId="ngModel" id="isaInterchangeReceiverId"
            [(ngModel)]="vendorFields.isaInterchangeReceiverId" [disabled]="disableFields">

        </mat-form-field>


      </div>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="PO Type" name="POType" #POType="ngModel" id="POType"
            [(ngModel)]="vendorFields.POType" [disabled]="disableFields">

        </mat-form-field>

      </div>
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Price Item Qualifier" name="priceItemQualifier" #priceItemQualifier="ngModel"
            id="priceItemQualifier" [(ngModel)]="vendorFields.priceItemQualifier" [disabled]="disableFields">
        </mat-form-field>
      </div>
    </div>
  </div>

</form>