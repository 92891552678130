import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';

@Component({
  selector: 'app-vendor-connection',
  templateUrl: './vendor-connection.component.html',
  styleUrls: ['./vendor-connection.component.css']
})
export class VendorConnectionComponent implements OnInit, OnChanges {

  user: any = {};
  hide: boolean = true;//for hiding eye icon for password
  vendorConnection: VendorConnection = {
    mId: '',
    NPINo: '',
    vendorCode: '',
    connectionType: '',
    url: '',
    ftpUsername: '',
    ftpPassword: '',
    port: 0,
    ackFileFormat: '',
    priceFileFormat: '',
    poFileFormat: '',
    inboundDirName: '',
    outboundDirName: '',
    encryptionType: 0,
    localInboundDirName: '',
    localOutboundDirName: '',
    encryptionKey: '',
    encryptionPassword: '',
    finalRemoteDestination: '',
    connectionSide: '',
    fileFormat810: '',
    ftpConnectionType: '',
    ftpLog: '',
    isDelete: false
  };
  editPort: boolean = true;//for disabling the port editing

  @ViewChild('vendorConnectionForm') public vendorConnForm!: NgForm;
  @Input() disableFields: boolean = false;
  @Input() receivedVendorCode: string = "";
  @Input() receivedVendorConn!: VendorConnection;


  constructor(private vendorService: VendorService, private utilityService: UtilityService) {
  }

  ngOnInit(): void {

    if (this.receivedVendorConn != undefined) {
      this.vendorConnection = this.receivedVendorConn;
    } else {
      this.vendorConnection.ftpConnectionType = "SFTP";//by default sftp will be visible and then user can change the configuration
      this.vendorConnection.port = 22;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['receivedVendorCode'] != undefined) {
      if (changes['receivedVendorCode'].currentValue)
        this.vendorConnection.vendorCode = changes['receivedVendorCode'].currentValue;
    }

    if (changes['receivedVendorConn'] != undefined) {
      if (changes['receivedVendorConn'].currentValue)
        this.vendorConnection = changes['receivedVendorConn'].currentValue;
    }

  }

  onConnectionTypeChange() {
    if (this.vendorConnection.ftpConnectionType == "SFTP") {
      this.vendorConnection.port = 22;

    } else if (this.vendorConnection.ftpConnectionType == "FTP") {
      this.vendorConnection.port = 21;
    } else {
      this.editPort = false;
    }
  }

  returnVendorConnData() {
    let retVendorData = {
      vendorConnection: this.vendorConnection,
      vendorConnForm: this.vendorConnForm
    }
    return retVendorData;
  }

  testVendorFtpConnection() {

    // let ftpData = {
    //   url: this.vendorConnection.url,
    //   port: this.vendorConnection.port,
    //   ftpUsername: this.vendorConnection.ftpUsername,
    //   ftpPassword: this.vendorConnection.ftpPassword
    // };
    this.vendorService.testVendorFtpConnection(this.vendorConnection).subscribe(response => {

      var receivedConnectionResponse = JSON.parse(response.data);
      var message = "";
      if (response.result == "SUCCESS") {
        if (receivedConnectionResponse.isFtpConnected == true) {

          if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == true) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory exists.\n" +
              "Outbound directory exists.\n";
          } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == true) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory does not exist.\n" +
              "Outbound directory exists.\n";
          } else if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == false) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory  exists.\n" +
              "Outbound directory does not exist.\n";
          } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == false) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory does not exist.\n" +
              "Outbound directory does not exist. \n";
          }
        } else if (receivedConnectionResponse.isFtpConnected == false) {

          this.utilityService.showAlert(
            "ERROR",
            "Failed",
            "FTP connection failed"
          );
        }

        this.utilityService.showAlert(
          "SUCCESS",
          "Info",
          message
        );
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while testing the connection"
        );
      }

    }, error => {

      if (error.error.result == "ERROR") {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "User name or password is wrong"
        );

      } else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while testing the connection"
        );
      }
    });

  }

  AllowDecimalNumber(event: any) {

    return this.utilityService.AllowDecimal(event);
  }

  AllowOnlyNumber(event: any) {
    return this.utilityService.AllowOnlyNumber(event);
  }

}
