<!-- card for header bar-->
<div class="row page-titles">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <h3 class="text-themecolor header-margin">Vendor Mapping</h3>
            <a style="color:#1e88e5 ;" matTooltip="Go to home" href="Home">Home > </a><label>Vendor Mapping</label>

        </div>

        <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"
            style="padding-top: 10px;padding-right: 10px;">
            <button mat-flat-button color="primary" (click)="openMappingDialog();" cdkFocusInitial
                style="float: right; ">
                Map With NPI
            </button>
        </div>

    </div>

</div>
<br>

<!-- card for header bar-->
<!-- card for search bar-->
<!-- ============================================================== -->

<div fxLayout="row wrap" fxLayout.xs="column">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card fxLayoutGap="30px">

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field style="padding-left:10px ;">
                    <input matInput placeholder="Short Name" name="PseudoName" #PseudoName="ngModel" id="PseudoName"
                        [(ngModel)]="vendor.pseudoName" readonly>
                </mat-form-field>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <mat-label>Select Vendor</mat-label>
                    <mat-select #vendorCode="ngModel" name="vendorCode" [(ngModel)]="vendor.vendorCode">
                        <mat-option *ngFor="let eachVendor of vendorArray" [value]="eachVendor.vendor.vendorCode">
                            {{ eachVendor.vendor.vendorCode}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                style="padding-top: 10px;padding-right: 10px;">
                <button mat-flat-button color="primary" cdkFocusInitial style="float: right;" (click)="getVendors()">
                    <mat-icon>search</mat-icon>Search
                </button>
            </div>

        </mat-card>
    </div>
</div>

<!-- TABLE FOR SHOWING THE DATA  -->

<!-- <div class="example-container mat-elevation-z8"> -->
<div fxLayout="row wrap" fxLayout.xs="column">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-top: 10px;padding-right: 10px;">
                    <button mat-raised-button matTooltip="Export to excel file"
                        (click)="exporter.exportTable('xlsx', {fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Excel
                    </button>
                    <button mat-raised-button matTooltip="Export to csv file"
                        (click)="exporter.exportTable('csv',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Csv
                    </button>
                    <button mat-raised-button matTooltip="Export to json file"
                        (click)="exporter.exportTable('json',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Json
                    </button>
                    <button mat-raised-button matTooltip="Export to txt file"
                        (click)="exporter.exportTable('txt',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Txt
                    </button>
                </div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-top: 10px;padding-right: 10px;">
                    <mat-form-field floatLabel="never" style="padding-right: 5px;float: right;">
                        <input matInput [(ngModel)]="searchKey" (keyup)="applyFilter()" placeholder="Search"
                            autocomplete="off">
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey"
                            (click)="onSearchClear()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>

        </mat-card>
    </div>
</div>
<div fxLayout="row wrap" fxLayout.xs="column">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card class=" mat-elevation-z24">
            <mat-card-content>
                <mat-sidenav-container>
                    <mat-sidenav-content>
                        <div class="mat-elevation-z8 m-12">
                            <div class="table-container">
                                <table mat-table matTableExporter #outerSort="matSort" matSort
                                    [dataSource]="VendorContractDataSource" #exporter="matTableExporter"
                                    style="border: 1px solid rgb(27, 170, 227);" multiTemplateDataRows
                                    class="mat-elevation-z8">

                                    <ng-container matColumnDef="Expand/Collapse">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-icon class="pointer" matSuffix matTooltip="See Vendor Contract(s)"
                                                (click)="showNested = !showNested">{{showNested ?
                                                'expand_less' : 'expand_more'}}
                                            </mat-icon>
                                        </td>
                                    </ng-container>

                                    <ng-container color="primary" matColumnDef="NPINo">
                                        <th mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>NPINo
                                        </th>
                                        <td mat-cell color="primary" *matCellDef="let element"> {{element.vendor.NPINo}}
                                        </td>
                                    </ng-container>

                                    <ng-container color="primary" matColumnDef="vendorCode">
                                        <th mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Vendor
                                            Code </th>
                                        <td mat-cell color="primary" *matCellDef="let element">
                                            {{element.vendor.vendorCode}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendorName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.vendorName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="address1">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Address1</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.address1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="city">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.city}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="state">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> State</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.state}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="zip">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zip</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.zip}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="isActive">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Active?</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.isActive}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button mat-icon-button matTooltip="Edit {{element.vendor.vendorCode}}"
                                                (click)="openVendorMappingUpdateDialog(element.vendor);"
                                                color="primary">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>
                                            <button mat-flat-button color="primary"
                                                (click)="AddContract(element.vendor.NPINo,element.vendor.vendorCode);">
                                                <mat-icon>add</mat-icon> Contract
                                            </button>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="expandedDetail">
                                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                            <div class="example-element-detail"
                                                *ngIf="element.contractDetail?.data.length"
                                                [@detailExpand]="element ==  expandedElement ? 'expanded' : 'collapsed'">
                                                <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">

                                                    <table #innerTables mat-table #innerSort="matSort"
                                                        style="border: 1px solid rgb(27, 170, 227);"
                                                        [dataSource]="element.contractDetail" matSort>

                                                        <ng-container color="primary" matColumnDef="contractName">
                                                            <th mat-header-cell color="primary" *matHeaderCellDef
                                                                mat-sort-header>
                                                                Contract Name</th>
                                                            <td mat-cell *matCellDef="let element">
                                                                {{element.contractName}}
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractUsername">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                ftp/sftp Username</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractUsername"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractUsername}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractPassword">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                ftp/sftp Password </th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractPassword"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName" [type]="hideOldPwd ? 'password' : 'text'" autocomplete="new-password">
                                                                            <mat-icon class="pointer" matSuffix (click)="hideOldPwd = !hideOldPwd">{{hideOldPwd ?
                                                                                'visibility_off' :
                                                                                'visibility'}}
                                                                            </mat-icon>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit" style="font-weight: bolder;">
                                                                                ******
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractIsaInterchangeSenderId">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                ISA Interchange Sender ID</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractIsaInterchangeSenderId"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractIsaInterchangeSenderId}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractAppSenderCode">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                App Sender Code</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractAppSenderCode"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractAppSenderCode}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractIdentificationCodeBy">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Identification Code By</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractIdentificationCodeBy"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractIdentificationCodeBy}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractOutboundDirName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Outbound Dir Name</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractOutboundDirName"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractOutboundDirName}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractInboundDirName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Inbound Dir Name</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractInboundDirName"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractInboundDirName}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractPriceFileFormat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Price FileFormat</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractPriceFileFormat"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractPriceFileFormat}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractAckFileFormat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Ack FileFormat</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractAckFileFormat"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractAckFileFormat}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractFileFormat810">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Invoice FileFormat</th>
                                                            <td mat-cell color="primary" *matCellDef="let element">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractFileFormat810"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractFileFormat810}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="Edit">
                                                            <th mat-header-cell *matHeaderCellDef> Edit </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <div *ngIf="!element.isEdit">
                                                                    <button mat-icon-button
                                                                        matTooltip="Edit {{element.contractName}}"
                                                                        (click)="onEdit(element)" color="primary">
                                                                        <mat-icon>mode_edit</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button
                                                                        matTooltip="Delete {{element.contractName}}"
                                                                        (click)="onDelete(element);" color="primary">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                                <div *ngIf="element.isEdit">
                                                                    <button mat-icon-button
                                                                        matTooltip="Update {{element.contractName}} changes"
                                                                        (click)="onUpdate(element);" color="primary">
                                                                        <mat-icon>check_circle_outline </mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button
                                                                        matTooltip="Cancel {{element.contractName}} changes"
                                                                        (click)="element.isEdit=false" color="primary">
                                                                        <mat-icon>highlight_off</mat-icon>
                                                                    </button>
                                                                    <button
                                                                        mat-flat-button
                                                                        matTooltip="Test {{element.contractName}} connection"
                                                                        (click)="testVendorFtpSftpConnection(element)" color="primary">
                                                                        Test Conn
                                                                    </button>
                                                                </div>

                                                            </td>
                                                        </ng-container>

                                                        <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: innerDisplayedColumns;">
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        [ngClass]="{hovered: row.hovered}"
                                        [class.example-element-row]="row.contractDetails?.data.length"
                                        [class.example-expanded-row]="expandedElement === row"
                                        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
                                        (click)="toggleRow(row)"></tr>

                                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                                        class="example-detail-row">
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </mat-sidenav-content>
                </mat-sidenav-container>
            </mat-card-content>
        </mat-card>

        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
        </mat-paginator>


    </div>
    <!-- </div> -->