<!-- card for header bar-->
<div class="row page-titles">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <h3 class="text-themecolor header-margin">File Parsing Error</h3>
            <a style="color:#1e88e5 ;" matTooltip="Go to home" href="Home">Home > </a><label>File Parsing Error</label>

        </div>

    </div>

</div>
<br>

<!-- card for search bar-->
<!-- ============================================================== -->
<div fxLayout="row wrap">

    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card fxLayoutGap="20px">

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field style="padding-left:10px ;">
                    <input matInput placeholder="Short Name" name="PseudoName" #PseudoName="ngModel" id="PseudoName"
                        [(ngModel)]="fileParsingError.pseudoName" readonly>
                </mat-form-field>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>From Date</mat-label>
                    <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fileParsingError.fromDate"
                        (dateChange)="onFromDateChange()" #fromDate="ngModel" name="fromDate" required readonly>
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                </mat-form-field>

                <mat-error *ngIf="fromDate.invalid && (fromDate.dirty || fromDate.touched)" class="invalid-feedback">
                    Please select from date
                </mat-error>
            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="toPicker" [(ngModel)]="fileParsingError.toDate" #toDate="ngModel"
                        (dateChange)="onToDateChange()" name="toDate" required readonly>
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>

                <mat-error *ngIf="toDate.invalid && (toDate.dirty || toDate.touched)" class="invalid-feedback">
                    Please select end date
                </mat-error>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <mat-label>Select file type</mat-label>
                    <mat-select #fileType="ngModel" name="fileType" [(ngModel)]="fileParsingError.fileType">
                        <mat-option value="832">832</mat-option>
                        <mat-option value="855">855</mat-option>
                        <mat-option value="810">810</mat-option>

                    </mat-select>
                </mat-form-field>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                style="padding-top: 10px;padding-right: 10px;">
                <button mat-flat-button color="primary" cdkFocusInitial style="float: right;"
                    (click)="getFileParseErrorData();">
                    <mat-icon>search</mat-icon>Search
                </button>
            </div>

        </mat-card>
    </div>

</div>



<!-- TABLE FOR SHOWING THE DATA  -->

<div class="example-container mat-elevation-z8">


    <div style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">
        <mat-card>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-top: 10px;padding-right: 10px;">
                    <button mat-raised-button matTooltip="Export to excel file"
                        (click)="exporter.exportTable('xlsx', {fileName:'parsing_error_Data', sheet: 'parsing_error_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Excel
                    </button>
                    <button mat-raised-button matTooltip="Export to csv file"
                        (click)="exporter.exportTable('csv',{fileName:'parsing_error_Data', sheet: 'parsing_error_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Csv
                    </button>
                    <button mat-raised-button matTooltip="Export to json file"
                        (click)="exporter.exportTable('json',{fileName:'parsing_error_Data', sheet: 'parsing_error_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Json
                    </button>
                    <button mat-raised-button matTooltip="Export to txt file"
                        (click)="exporter.exportTable('txt',{fileName:'parsing_error_Data', sheet: 'parsing_error_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Txt
                    </button>
                </div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-top: 10px;padding-right: 10px;">
                    <mat-form-field floatLabel="never" style="padding-right: 5px;float: right;">
                        <input matInput [(ngModel)]="searchKey" (keyup)="applyFilter()" placeholder="Search"
                            autocomplete="off">
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey"
                            (click)="onSearchClear()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>

        </mat-card>

        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

            <!-- Checkbox Column -->

            <ng-container color="primary" matColumnDef="NPINo">
                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>NPINo</b> </mat-header-cell>
                <mat-cell color="primary" *matCellDef="let element"> {{element.NPINo}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="vendorCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b> Vendor Code</b> </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.vendorCode}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contractName">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b> Vendor Contract</b></mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.contractName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fileName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> <b>File Name</b></mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.fileName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fileType">
                <mat-header-cell *matHeaderCellDef mat-sort-header> <b>File Type</b></mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.fileType}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="errorDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header><b> Error Date</b></mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.errorDate | date: "short"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="errorMessages">
                <mat-header-cell *matHeaderCellDef mat-sort-header> <b>Error Messages</b></mat-header-cell>
                <mat-cell class="break-word" style="color:red ;" *matCellDef="let element"> {{element.errorMessages}}
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

        </mat-table>

        <!-- <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5,10,20]" showFirstLastButtons>
      </mat-paginator> -->

        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>

    </div>
</div>