import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { userAccess } from 'src/app/models/userAccess';
import { Vendor } from 'src/app/models/Vendor';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { VendorFields } from 'src/app/models/VendorFields';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { AlertDialogComponent } from 'src/app/utility/alert-dialog/alert-dialog.component';
import { VendorConnectionComponent } from '../../vendorComponents/vendor-connection/vendor-connection.component';
import { VendorFieldsComponent } from '../../vendorComponents/vendor-fields/vendor-fields.component';
import { VendorInformationComponent } from '../../vendorComponents/vendor-information/vendor-information.component';

@Component({
  selector: 'app-vendor-map-dialog',
  templateUrl: './vendor-map-dialog.component.html',
  styleUrls: ['./vendor-map-dialog.component.css']
})
export class VendorMapDialogComponent implements OnInit {

  user: any = {};
  vendorInfoFormData: any;
  vendorConnFormValues: any;
  vendorFieldsFormValues: any;
  selectedIndex: number = 0;
  maxNumberOfTabs: number = 2;
  isUpdate: boolean = false;//for knowing update or create and added condition for show hide
  isCreate: boolean = false;//for knowing update or create and added condition for show hide
  userAccessData!: userAccess;
  isMMSAdmin: boolean = false;
  //for getting the child component data for further processing 
  @ViewChild('vendorInformation') private vendorInfoData!: VendorInformationComponent;
  @ViewChild('vendorConnection') private vendorConnData!: VendorConnectionComponent;
  @ViewChild('vendorFields') private vendorFieldsData!: VendorFieldsComponent;

  //declaring the models for populating forms
  dbVendorInfoData: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier:'',
    costQualifier:'',
    saleQualifier:'',
    isEPO:false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File:false
  };
  dbVendorFields: VendorFields = {
    mId: '',
    NPINo: '',
    vendorCode: '',
    segmentSeperator: '',
    elementSeperator: '',
    subElementSeperator: '',
    isaIdQualifierSender: '',
    isaIdQualifierReceiver: '',
    isaInterchangeSenderId: '',
    isaInterchangeReceiverId: '',
    ieaInterchangeControlNo: '',
    isaTestIndicator: '',
    appSenderCode: '',
    appReceiverCode: '',
    POType: '',
    idCodeQualifierBy: 0,
    identificationCodeBy: '',
    idCodeQualifierSE: 0,
    identificationCodeSE: 0,
    productQualifier: '',
    isaInterchangeCtrlNo: '',
    isaAcknowledgementRequest: '',
    version: '',
    standardType: '',
    perName: '',
    perContactFunctionCode: '',
    perCommNumQualifier: '',
    perCommNum: '',
    amtAmountQualifier: '',
    AMT_Amount: '',
    priceItemQualifier: '',
    sellingPriceQualifier: '',
    useVICForEPO: 0,
    isaInterchangeCtrlVerNo: '',
    vendorRefQualifierNo: '',
    vendorRefNo: '',
    storeRefQualifierNo: '',
    storeRefNo: '',
    customerRefQualifierNo: '',
    customerRefNo: '',
    accountNo: '',
    divisionNo: '',
    application: '',
    copyOtherVendorField: '',
    isDelete: false,
    // fileExtension: [],
    isActive: false
  };
  dbVendorConnection: VendorConnection = {
    mId: '',
    NPINo: '',
    vendorCode: '',
    connectionType: '',
    url: '',
    ftpUsername: '',
    ftpPassword: '',
    port: 0,
    ackFileFormat: '',
    priceFileFormat: '',
    poFileFormat: '',
    inboundDirName: '',
    outboundDirName: '',
    encryptionType: 0,
    localInboundDirName: '',
    localOutboundDirName: '',
    encryptionKey: '',
    encryptionPassword: '',
    finalRemoteDestination: '',
    connectionSide: '',
    fileFormat810: '',
    ftpConnectionType: '',
    ftpLog: '',
    isDelete: false
  };

  constructor(private utilityService: UtilityService, private vendorService: VendorService, private userService: UserService, @Inject(MAT_DIALOG_DATA) public data: any, private vendorMapDialogRef: MatDialogRef<VendorMapDialogComponent>) {

    this.userAccessData = userService.getUsersAccessData();
    if (this.userAccessData.NPINo == "0000000000")
      this.isMMSAdmin = true;

    //receiving the data from the parent component and assigning it here
    if (data != undefined && data.receivedVendorInfo != undefined && data.dbVendorFields != undefined && data.dbVendorConnection != undefined) {
      this.dbVendorInfoData = data.receivedVendorInfo;
      this.dbVendorFields = data.dbVendorFields;
      this.dbVendorConnection = data.dbVendorConnection;
      this.isUpdate = data.isUpdate;
    }

  }

  ngOnInit(): void {
  }

  moveToNextTab() {

    if (this.selectedIndex != this.maxNumberOfTabs) {


      this.vendorInfoFormData = this.vendorInfoData.returnVendorInfoData();
      if (this.selectedIndex == 0) {

        if (!this.vendorInfoFormData.vendorInfoForm.invalid) {
          // this.vendorInfoCodeValue = this.vendorInfoFormData.vendorInformation.vendorCode;
          this.selectedIndex = this.selectedIndex + 1;
          return;
        } else {

          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "Please fill required fields vendor information tab",
          );
        }

      }

      if (this.selectedIndex == 1) {

        this.vendorConnFormValues = this.vendorConnData.returnVendorConnData();

        if (!this.vendorConnFormValues.vendorConnForm.invalid) {
          this.selectedIndex = this.selectedIndex + 1;
          return;
        } else {

          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "Please fill required fields vendor connection tab",
          );

        }

      }

      if (this.selectedIndex == 2) {

        this.vendorFieldsFormValues = this.vendorFieldsData.returnVendorFieldsData();

        if (!this.vendorFieldsFormValues.vendorFieldsForm.invalid) {
          this.selectedIndex = this.selectedIndex + 1;
          return;
        } else {

          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "Please fill required fields vendor fields tab",
          );
        }

      }
    }
  }


  previousStep() {
    if (this.selectedIndex != 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
    console.log(this.selectedIndex);
  }

  getFullMasterVendor(event: any) {
    let passVendor = {
      vendorCode: event
    };
    this.vendorService.getFullActiveVendorsByCode(passVendor).subscribe((response: any) => {

      if (response.result == "SUCCESS") {
        var masterVendorResponse = JSON.parse(response.data);

        this.dbVendorInfoData = masterVendorResponse.vendor;
        this.dbVendorFields = masterVendorResponse.vendorFields;
        this.dbVendorConnection = masterVendorResponse.vendorConnection;
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching master vendors data"
        );
      }

    });
  }

  mapFullVendorWithNPI() {

    //returning the vendor field data
    this.vendorFieldsFormValues = this.vendorFieldsData.returnVendorFieldsData();

    if (this.isMMSAdmin) {

      //setting the npi no
      this.vendorFieldsFormValues.vendorFields.NPINo = this.vendorInfoFormData.vendorInformation.NPINo;
      this.vendorConnFormValues.vendorConnection.NPINo = this.vendorInfoFormData.vendorInformation.NPINo;
    } else {
      //setting the npi no
      this.vendorInfoFormData.vendorInformation.NPINo= this.userAccessData.NPINo;
      this.vendorFieldsFormValues.vendorFields.NPINo = this.userAccessData.NPINo;
      this.vendorConnFormValues.vendorConnection.NPINo = this.userAccessData.NPINo;

    }

    //making the object to pass in api
    let fullVendor = {
      vendor: this.vendorInfoFormData.vendorInformation,
      vendorFields: this.vendorFieldsFormValues.vendorFields,
      vendorConnection: this.vendorConnFormValues.vendorConnection
    }

    this.vendorService.mapFullVendorWithNPI(fullVendor).subscribe((response: any) => {

      if (response.result == "SUCCESS") {
        this.utilityService.showAlert(
          "SUCCESS",
          "Mapped",
          "Vendor mapped successfully with NPINo:" + this.vendorInfoFormData.vendorInformation.NPINo
        ).subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.vendorMapDialogRef.close(true)
        });


      }
      else {
        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while mapping vendor with NPINo:" + this.vendorInfoFormData.vendorInformation.NPINo,
        );

      }

    });

  }

  updateVendorConnection() {

    //returning the vendor field data
    this.vendorConnFormValues = this.vendorConnData.returnVendorConnData();


    if (!this.vendorConnFormValues.vendorConnection.url) {
      this.utilityService.showAlert(
        "WARNING",
        "Warning",
        "Please Enter FTP/SFTP URL."
      );
      return;
    }

    // if (!this.vendorConnFormValues.vendorConnection.ftpPassword) {
    //   this.utilityService.showAlert(
    //     "WARNING",
    //     "Warning",
    //     "Please enter ftp password"
    //   );
    //   return;
    // }

    this.vendorService.updateVendorConnection(this.vendorConnFormValues.vendorConnection).subscribe((response: any) => {

      if (response.result == "SUCCESS") {
        this.utilityService.showAlert(
          "SUCCESS",
          "Updated",
          "Vendor connection updated successfully"
        ).subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.vendorMapDialogRef.close(true)
        });
      }
      else {
        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while updating the vendor connection"
        );

      }

    });

  }

  updateVendorInformation() {

    this.vendorInfoFormData = this.vendorInfoData.returnVendorInfoData();

    if (!this.vendorInfoFormData.vendorInformation.priceQualifier) {
      this.utilityService.showAlert(
        "WARNING",
        "Warning",
        "Please Enter Price Qualifier."
      );
      return;
    }

    if (!this.vendorInfoFormData.vendorInformation.costQualifier) {
      this.utilityService.showAlert(
        "WARNING",
        "Warning",
        "Please Enter Cost Qualifier."
      );
      return;
    }

    if (!this.vendorInfoFormData.vendorInformation.saleQualifier) {
      this.utilityService.showAlert(
        "WARNING",
        "Warning",
        "Please Enter Sale Qualifier."
      );
      return;
    }

    this.vendorService.updateVendorInformation(this.vendorInfoFormData.vendorInformation).subscribe((response: any) => {

      if (response.result == "SUCCESS") {
        this.utilityService.showAlert(
          "SUCCESS",
          "Updated",
          "Vendor information updated successfully."
        ).subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          // this.vendorMapDialogRef.close(true)
        });
      }
      else {
        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while updating the vendor information."
        );

      }

    });

  }
}
