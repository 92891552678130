import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpClient: HttpClient) { }


  createFullVendor(vendorData: any) {
    return this.httpClient.post<any>(constant.createFullVendorUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getMasterVendor(vendorData: any) {
    return this.httpClient.post<any>(constant.getMasterVendorUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getMasterVendorConnectionAndField(vendorData: any) {
    return this.httpClient.post<any>(constant.getMasterVendorConnAndFieldsUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getVendorConnectionAndField(vendorData: any) {
    return this.httpClient.post<any>(constant.getVendorConnAndFieldsUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getVendorData(vendorData: any) {
    return this.httpClient.post<any>(constant.getVendorUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  getVendorWithContractData(vendorData: any) {
    return this.httpClient.post<any>(constant.getVendorWithContractUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  saveVendorWithContractData(vendorData: any) {
    return this.httpClient.post<any>(constant.saveVendorWithContractUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )

  }

  getFullActiveVendorsByCode(vendorData: any) {
    return this.httpClient.post<any>(constant.getFullActiveVendorsByCodeUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  mapFullVendorWithNPI(vendorData: any) {
    return this.httpClient.post<any>(constant.mapFullVendorWithNPINoUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  deleteMasterVendor(vendorData: any) {

    return this.httpClient.post<any>(constant.deleteMasterVendorUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  updateMasterVendor(vendorData: any) {

    return this.httpClient.post<any>(constant.updateMasterVendorUrl, vendorData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  updateVendorConnection(vendorConnData: any) {

    return this.httpClient.post<any>(constant.updateVendorConnectionUrl, vendorConnData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  updateVendorInformation(vendorInfoData: any) {

    return this.httpClient.post<any>(constant.updateVendorInformationUrl, vendorInfoData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  getFTPScheduler() {

    return this.httpClient.get<any>(constant.getFTPSchedulerUrl, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }


  SaveFTPScheduler(storeGeneralConfigData: any) {

    return this.httpClient.post<any>(constant.saveFTPSchedulerUrl, storeGeneralConfigData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  createVendorContract(vendorWithContarct: any) {

    return this.httpClient.post<any>(constant.saveVendorWithContractUrl, vendorWithContarct, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  deleteVendorContract(vendorWithContarct: any) {

    return this.httpClient.post<any>(constant.deleteVendorWithContractUrl, vendorWithContarct, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }

  testVendorFtpConnection(vendorWithContarct: any) {
    return this.httpClient.post<any>(constant.testVendorFtpConnectionUrl, vendorWithContarct, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  checkIfContractAlreadyExists(vendorWithContarct: any) {

    return this.httpClient.post<any>(constant.checkIfContractAlreadyExists, vendorWithContarct, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )
  }
}
