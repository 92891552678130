import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EdiComponentsRoutingModule } from './edi-components-routing.module';
import { UserComponent } from './user/user/user.component';
import { UserModalComponent } from './user/userModal/user-modal.component';
import { MaterialModule } from '../materialComponents/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { FileDownloadComponent } from './fileDownload/file-download.component';
// import { FileParsingErrorComponent } from './fileParsingError/file-parsing-error.component';
import { VendorInformationComponent } from './vendorComponents/vendor-information/vendor-information.component'; 
import { VendorConnectionComponent } from './vendorComponents/vendor-connection/vendor-connection.component'; 
import { VendorFieldsComponent } from './vendorComponents/vendor-fields/vendor-fields.component'; 
import { VendorMappingComponent } from './vendorMapping/vendor-mapping.component';
import { VendorMapDialogComponent } from './vendorMapping/vendor-map-dialog/vendor-map-dialog.component';
import { VendorMasterDialogComponent } from './vendorMaster/vendor-master-dialog/vendor-master-dialog.component';
import { VendorMasterComponent } from './vendorMaster/vendor-master.component';
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { ChangePasswordComponent } from './user/changePassword/change-password.component';
import { HomeComponent } from './home/home.component';
import { NgxMaskModule } from 'ngx-mask';
import { FileParsingErrorComponent } from './fileParsingError/file-parsing-error.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { CronDialogComponent } from './scheduler/cron-dialog/cron-dialog.component';
import { ContractModalComponent } from './vendorMapping/vendorContract/contract-modal.component';

@NgModule({
  declarations: [
    UserComponent,
    UserModalComponent,
    FileDownloadComponent,
    VendorInformationComponent,
    VendorConnectionComponent,
    VendorFieldsComponent,
    VendorMappingComponent,
    VendorMapDialogComponent,
    VendorMasterDialogComponent,
    VendorMasterComponent,
    ChangePasswordComponent,
    HomeComponent,
    FileParsingErrorComponent,
    SchedulerComponent,
    CronDialogComponent,
    ContractModalComponent,
  ],
  imports: [
    CommonModule,
    EdiComponentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbModule,
    DataTablesModule,
    MatPasswordStrengthModule.forRoot(),
    NgxMaskModule.forRoot()
  ]
})
export class EdiComponentsModule { }
