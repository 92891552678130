import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { timeStamp } from 'console';
import { startWith, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { stateConstant } from 'src/app/constant/stateConstant';
import { State } from 'src/app/models/State';
import { Store } from 'src/app/models/Store';
import { userAccess } from 'src/app/models/userAccess';
import { Vendor } from 'src/app/models/Vendor';
import { StoreService } from 'src/app/services/Store/store.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { constant } from '../../../constant/constant.dev';

export interface test {
  name: string;
  post: string;
}
@Component({
  selector: 'app-vendor-information',
  templateUrl: './vendor-information.component.html',
  styleUrls: ['./vendor-information.component.css']
})
export class VendorInformationComponent implements OnInit, OnChanges {

  user: any = {};
  vendorInformation: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier:'',
    costQualifier:'',
    saleQualifier:'',
    isEPO:false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File:false
  };

  stateArray: State[] = stateConstant.states;
  priceQualifier: string[] = constant.qualifiers;
  costQualifier: string[] = constant.qualifiers;
  saleQualifier: string[] = constant.qualifiers;
  storeArray!: Store[];
  vendorMasterArray: Vendor[] = [];
  isMMSAdmin: boolean = false;
  existingMappedVendor: Vendor[] = [];
  unMappedVendor: Vendor[] = [];
  userAccessData!: userAccess;
  selectedNPINo: string = "";
  selectedVendorCode: string = "";
  @ViewChild('vendorInformationForm') public vendorInfoForm!: NgForm;
  @Input() disableFields: boolean = false;
  @Input() receivedVendorInfo!: Vendor;
  @Input() isFromMapping: boolean = false;//this is to check from where the component is called and on this added show hide condition
  @Input() isUpdate: boolean = false; //for knowing update or create and added condition for show hide
  @Input() isCreate: boolean = false; //for knowing update or create and added condition for show hide

  @Output("getFullMasterVendor") passVendorCodeToMappingDialog: EventEmitter<any> = new EventEmitter();

  //variable for searchable dropdown
  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;

  vendorNameControl = new FormControl('');
  filteredVendorOptions: Observable<Vendor[]> | undefined;


  constructor(private storeService: StoreService, private vendorService: VendorService, private userService: UserService, private utilityService: UtilityService) {
    this.userAccessData = this.userService.getUsersAccessData();
    if (this.userAccessData.NPINo != "" && this.userAccessData.NPINo == "0000000000") {
      this.isMMSAdmin = true;
      this.loadAllNPINo();
    }
    this.loadMasterVendors();

  }


  ngOnInit(): void {
    if (this.receivedVendorInfo != undefined) {
      this.vendorInformation = this.receivedVendorInfo;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //THIS DATA IS BEING FORWARED FROM PARENT COMPONENT AND THIS METHOD CHECKS IF THE DATA IS CHANGED AND THEN IT ASSIGNES THE DATA
    if (changes['receivedVendorInfo'] != undefined) {
      if (changes['receivedVendorInfo'].currentValue != undefined){
        this.vendorInformation = changes['receivedVendorInfo'].currentValue;
        if(this.selectedNPINo)
        this.vendorInformation.NPINo=this.selectedNPINo;
      }

    }

    if (changes['isFromMapping'] != undefined) {
      //FOR SHOWING THE NPI FILTER TO MMS ADMIN
      if (changes['isFromMapping'].currentValue != undefined)
        this.isFromMapping = changes['isFromMapping'].currentValue;

    }

  }

  setValueToNPIDropdown() {

    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  setNPINoToModel(event: any) {
    this.vendorInformation.NPINo = event.option.value.NPINo;

    this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

    //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
    this.getUnMappedVendors(this.vendorInformation.NPINo);

  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }

  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  returnVendorInfoData() {
    let retVendorData = {
      vendorInformation: this.vendorInformation,
      vendorInfoForm: this.vendorInfoForm
    }
    return retVendorData;
  }

  //=======will fetch all the NPINo presenet in the store =============
  loadAllNPINo() {

    this.storeService.getAllNPINo().subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedVendorResponse = JSON.parse(response.data);
        this.storeArray = receivedVendorResponse;
        this.setValueToNPIDropdown();
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching list of NPINo"
        );
      }

    });
  }


  //=======will fetch all the master vendor and populate the vendor dropdown =============
  loadMasterVendors() {

    let vendorData = {};
    this.vendorService.getMasterVendor(vendorData).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedVendorResponse = JSON.parse(response.data);
        this.vendorMasterArray = receivedVendorResponse;
        this.getUnMappedVendors(this.userAccessData.NPINo);

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching list of NPINo"
        );
      }

    });
  }


  //#region master vendor dropdown
  //this methods are user for filtering the data from the vendor master array
  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  setVendorNameToModel(event: any) {
    // this.vendorInformation.vendorCode = event.option.value.vendorCode;
    //take out the vendor field and vendor connection data

    this.selectedVendorCode = event.option.value.vendorCode;//adding the value of selected vendor code to the field
    //calling the vendor map dialog method for sending vendor code data
    this.passVendorCodeToMappingDialog.emit(event.option.value.vendorCode);

  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }

  displayVendorName(vendorData: Vendor): string {
    return vendorData && vendorData.vendorCode ? vendorData.vendorCode : '';
  }

  //#endregion Region Name Here

  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe((response: any) => {

      if (response.result == "SUCCESS") {
        var receivedVendorResponse = JSON.parse(response.data);
        this.existingMappedVendor = receivedVendorResponse;

        //now getting the diffrence between the master vendor and mapped  vendor
        this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
          !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

        //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
        this.setValueToVendorDropdown();
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching mapped vendors data"
        );
      }

    });
  }

  AllowOnlyNumber(event :any){
    
    return this.utilityService.AllowOnlyNumber(event);

  }
  
  AllowOnlyCharacter(event :any){
    
    return this.utilityService.AllowOnlyCharacter(event);

  }

}
