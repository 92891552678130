import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private httpClient: HttpClient) { }

  getAllNPINo() {
    return this.httpClient.get<any>(constant.getAllNPINoUrl,  constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }
}
