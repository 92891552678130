import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileDownloadGuard } from '../guards/file-download.guard';
import { FileParsingErrorGuard } from '../guards/file-parsing-error.guard';
import { HomeGuard } from '../guards/home.guard';
import { UserGuard } from '../guards/user.guard';
import { VendorMappingGuard } from '../guards/vendor-mapping.guard';
import { VendorMasterGuard } from '../guards/vendor-master.guard';
import { PageNotFoundComponent } from '../utility/pageNotFound/page-not-found.component';
import { FileDownloadComponent } from './fileDownload/file-download.component';
import { FileParsingErrorComponent } from './fileParsingError/file-parsing-error.component';
import { HomeComponent } from './home/home.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { UserComponent } from './user/user/user.component';
import { VendorMappingComponent } from './vendorMapping/vendor-mapping.component';
import { VendorMasterComponent } from './vendorMaster/vendor-master.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'home', component: HomeComponent,canActivate:[HomeGuard]},
  { path: '', component: UserComponent,canActivate:[UserGuard]}, 
  { path: 'user', component: UserComponent,canActivate:[UserGuard]}, 
  { path: 'fileDownload', component: FileDownloadComponent,canActivate:[FileDownloadGuard]}, 
  { path: 'fileParsingError', component: FileParsingErrorComponent,canActivate:[FileParsingErrorGuard]}, 
  { path: 'vendorMapping', component: VendorMappingComponent,canActivate:[VendorMappingGuard]}, 
  { path: 'vendorMaster', component: VendorMasterComponent,canActivate:[VendorMasterGuard]},  
  {path: 'scheduler', component: SchedulerComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EdiComponentsRoutingModule { }
