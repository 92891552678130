<!-- card for header bar-->
<div class="row page-titles">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

    <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
      <h3 class="text-themecolor header-margin">User</h3>
      <a style="color:#1e88e5 ;" matTooltip="Go to home" href="Home">Home > </a><label>User</label>

    </div>

    <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"
      style="padding-top: 10px;padding-right: 10px;">
      <button mat-flat-button color="primary" cdkFocusInitial style="float: right; " (click)="openUserCreateModal();">
        <mat-icon>add</mat-icon> Create
      </button>
    </div>

  </div>

</div>
<br>

<!-- card for search bar-->
<!-- ============================================================== -->
<div fxLayout="row wrap">

  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card fxLayoutGap="20px">

      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field style="padding-left:10px ;">
          <input matInput placeholder="Short Name" name="PseudoName" #PseudoName="ngModel" id="PseudoName"
            [(ngModel)]="user.pseudoName" readonly>
        </mat-form-field>

      </div>

      <div fxLayoutAlign="center center" style="padding-left: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field fxFlex="55">
          <input matInput placeholder="User Name" [(ngModel)]="user.userName" name="UserName" class="form-group">
        </mat-form-field>

      </div>

      
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100"
        style="padding-top: 10px;padding-right: 10px;">
        <button mat-flat-button color="primary" cdkFocusInitial style="float: right;" (click)="getUsers();">
          <mat-icon>search</mat-icon>Search
        </button>
      </div>

    </mat-card>
  </div>

</div>


<div class="example-container mat-elevation-z8">

  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
    style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">
    <mat-card>
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                  style="padding-top: 10px;padding-right: 10px;">
                  <button mat-raised-button matTooltip="Export to excel file"
                      (click)="exporter.exportTable('xlsx', {fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Excel
                  </button>
                  <button mat-raised-button matTooltip="Export to csv file"
                      (click)="exporter.exportTable('csv',{fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Csv
                  </button>
                  <button mat-raised-button matTooltip="Export to json file"
                      (click)="exporter.exportTable('json',{fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Json
                  </button>
                  <button mat-raised-button matTooltip="Export to txt file"
                      (click)="exporter.exportTable('txt',{fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Txt
                  </button>
              </div>
  
              <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>
  
              <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
                  style="padding-top: 10px;padding-right: 10px;">
                  <mat-form-field floatLabel="never" style="padding-right: 5px;float: right;">
                      <input matInput [(ngModel)]="searchKey" (keyup)="applyFilter()" placeholder="Search"
                          autocomplete="off">
                      <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey"
                          (click)="onSearchClear()">
                          <mat-icon>close</mat-icon>
                      </button>
                  </mat-form-field>
              </div>
      </div>

  </mat-card>

    <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

      <!-- Checkbox Column -->

      <ng-container color="primary" matColumnDef="NPINo">
        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>NPINo</b> </mat-header-cell>
        <mat-cell color="primary" *matCellDef="let element"> {{element.NPINo}} </mat-cell>
      </ng-container>

      <ng-container color="primary" matColumnDef="userName">
        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>User Id</b> </mat-header-cell>
        <mat-cell color="primary" *matCellDef="let element"> {{element.userName}} </mat-cell>
      </ng-container>

      <ng-container color="primary" matColumnDef="lName">
        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>Name</b> </mat-header-cell>
        <mat-cell color="primary" *matCellDef="let element"> {{element.lName}} </mat-cell>
      </ng-container>

      <ng-container color="primary" matColumnDef="noOfAttempt">
        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>No of Login Attempts</b>
        </mat-header-cell>
        <mat-cell color="primary" style="text-align: right;" *matCellDef="let element"> {{element.noOfAttempt}}
        </mat-cell>
      </ng-container>

      <ng-container color="primary" matColumnDef="isLocked">
        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>Is Locked?</b> </mat-header-cell>
        <mat-cell color="primary" *matCellDef="let element"> {{element.isLocked}} </mat-cell>
      </ng-container>

      <ng-container color="primary" matColumnDef="isActive">
        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>Is Active?</b> </mat-header-cell>
        <mat-cell color="primary" *matCellDef="let element"> {{element.isActive}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Edit user" color="primary" (click)="openUpdateUserModal(element);">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete user" color="warn" (click)="deleteUser(element);">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

    </mat-table>

    <!-- <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5,10,20]" showFirstLastButtons>
    </mat-paginator> -->

    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
    </mat-paginator>

  </div>
</div>