import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';
import { FileParseErrorRequest } from 'src/app/models/RequestModel/FileParseErrorRequest';

@Injectable({
  providedIn: 'root'
})
export class FileParsingErrorService {

  constructor(private httpClient: HttpClient) { }


  getFileParseErrorData(fileData: FileParseErrorRequest) {
    // return this.httpClient.post<any>(constant.getfileParseErrorUrl, fileData, constant.httpClientHeader);

    return this.httpClient.post<any>(constant.getfileParseErrorUrl, fileData, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

}
