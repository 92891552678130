import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './materialComponents/material.module';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PromptDialogComponent } from './utility/prompt-dialog/prompt-dialog.component';
import { AlertDialogComponent } from './utility/alert-dialog/alert-dialog.component';
import { EdiComponentsModule } from './ediComponents/edi-components.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CountdownAlertComponent } from './utility/countdown-alert/countdown-alert.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { ChangePasswordAtLoginComponent } from './changePasswordAtLogin/change-password-at-login.component';
import { PageNotFoundComponent } from './utility/pageNotFound/page-not-found.component';
import { UnAuthorisePageComponent } from './utility/unAuthorisePage/un-authorise-page.component';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ContractReplaceOrNoComponent } from './utility/contractReplaceAlert/contract-replace-or-no.component';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    PromptDialogComponent,
    AlertDialogComponent,
    CountdownAlertComponent,
    ChangePasswordAtLoginComponent,
    PageNotFoundComponent,
    UnAuthorisePageComponent,
    LoginWithoutCaptchaComponent,
    ContractReplaceOrNoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    EdiComponentsModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
 
  providers:[
     [{provide: LocationStrategy, useClass: HashLocationStrategy}],
     [{ provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings, },]
],
  bootstrap: [AppComponent]
})
export class AppModule { }
