<!-- card for header bar-->
<div class="row page-titles">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
  
      <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
        <h3 class="text-themecolor header-margin">Home</h3>
  
      </div>
  
    </div>
  
  </div>
  <br>


  <div fxLayout="row wrap">

    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-card fxLayoutGap="20px">
  
        <h1>Welcome</h1>
  
      </mat-card>
    </div>
  
  </div>