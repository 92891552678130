import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UserModalComponent } from '../userModal/user-modal.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  //=============VARIABLES===============
  hideOldPwd: boolean = true;
  hideNewPwd: boolean = true;
  hideConfirmPwd: boolean = true;
  userData: any = {};
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  showDetails: boolean = true;
  showError: boolean = false;
  changePasswordAtLogin: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private userService: UserService, private dialogRefPassword: MatDialogRef<ChangePasswordComponent>, private utilityService: UtilityService, private router: Router) {
    this.userData.userName = receivedData.userName;
    this.userData.NPINo = receivedData.NPINo;
    
    if (receivedData != undefined && receivedData.changePasswordAtLogin != undefined) {
      this.changePasswordAtLogin = receivedData.changePasswordAtLogin;

    }
  }

  ngOnInit(): void {
  }

  onPasswordStrengthChanged(event: any) {
    if (event == 100)
      this.isPasswordStrong = true;

  }



  changeUserPassword() {

    this.userService.changeUserPassword(this.userData).subscribe(response => {

      var receivedUserResponse = JSON.parse(response.data);

      if (response.result == "SUCCESS") {

        this.utilityService.showAlert(
          "SUCCESS",
          "Success",
          "Password changed successfully"
        ).subscribe(result => {

          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "You have changed the password please login again with new password"
          ).subscribe(result => {

            this.userService.logOutUser();
            this.dialogRefPassword.close();
          });
        });
      } else if (response.result == "ERROR") {

        this.utilityService.showAlert(
          "WARNING",
          "Warning",
          receivedUserResponse.result
        );


      } else {
        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while changing the password"
        );
      }

    });

  }

  changeUserPasswordAtLogin() {

    this.userService.changePasswordAtLogin(this.userData).subscribe(response => {

      var receivedUserResponse = JSON.parse(response.data);

      if (response.result == "SUCCESS") {

        this.utilityService.showAlert(
          "SUCCESS",
          "Success",
          "Password changed successfully"
        ).subscribe(result => {

          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "You have changed the password please login again with new password"
          ).subscribe(result => {

            this.userService.logOutUser();
            this.dialogRefPassword.close();
          });
        });
      } else if (response.result == "ERROR") {

        this.utilityService.showAlert(
          "WARNING",
          "Warning",
          receivedUserResponse.result
        );


      } else {
        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while changing the password"
        );
      }

    });

  }


}
