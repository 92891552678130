import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) { }

  getRoles(roleFilter:any){
    return this.httpClient.post<any>(constant.getRoleUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {
        
        return response;
      })
    )

  }
}
