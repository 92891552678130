import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class VendorOrderService {

  constructor(private httpClient: HttpClient) { }

  UpdatePOResponse810CronScheduler(cronExpression: any) {

    return this.httpClient.post<any>(constant.updateInvoiceCronScheduler, cronExpression, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
  UpdatePOResponse855CronScheduler(cronExpression: any) {

    return this.httpClient.post<any>(constant.updateACKCronScheduler, cronExpression, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
  UpdatePORequestCronScheduler(cronExpression: any) {

    return this.httpClient.post<any>(constant.updatePOCronScheduler, cronExpression, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}