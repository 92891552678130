import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RETURN } from 'mat-table-exporter';
import { Vendor } from 'src/app/models/Vendor';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { VendorFields } from 'src/app/models/VendorFields';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { VendorConnectionComponent } from '../../vendorComponents/vendor-connection/vendor-connection.component';
import { VendorFieldsComponent } from '../../vendorComponents/vendor-fields/vendor-fields.component';
import { VendorInformationComponent } from '../../vendorComponents/vendor-information/vendor-information.component';
import { constant } from '../../../constant/constant.dev';

@Component({
  selector: 'app-vendor-master-dialog',
  templateUrl: './vendor-master-dialog.component.html',
  styleUrls: ['./vendor-master-dialog.component.css']
})
export class VendorMasterDialogComponent implements OnInit {


  user: any = {};
  vendorInfoCodeValue: string = "";
  vendorInfoFormData: any;
  vendorConnFormValues: any;
  vendorFieldsFormValues: any;
  selectedIndex: number = 0;
  maxNumberOfTabs: number = 2;
  passFullVendorData: any = {};
  alertData: any = {};
  receivedVendorInfoData!: Vendor;
  dbVendorConnection!: VendorConnection;
  dbVendorFields!: VendorFields;

  //newly added
  receivedVendorConnectionData !: VendorConnection;
  isUpdate: boolean = false;//for knowing update or create and added condition for show hide

  //for getting the child component data for further processing 
  @ViewChild('vendorInformation') private vendorInfoData!: VendorInformationComponent;
  @ViewChild('vendorConnection') private vendorConnData!: VendorConnectionComponent;
  @ViewChild('vendorFields') private vendorFieldsData!: VendorFieldsComponent;


  constructor(private utilityService: UtilityService, private vendorService: VendorService, @Inject(MAT_DIALOG_DATA) public data: any, private vendorMasterDialogRef: MatDialogRef<VendorMasterDialogComponent>) {
    //receiving the data from the parent component and assigning it here
    if (data != undefined && data.receivedVendorInfo != undefined && data.dbVendorFields != undefined && data.dbVendorConnection != undefined) {
      this.receivedVendorInfoData = data.receivedVendorInfo;
      this.dbVendorFields = data.dbVendorFields;
      this.dbVendorConnection = data.dbVendorConnection;
      this.isUpdate = data.isUpdate;
    }
  }

  ngOnInit(): void {

  }

  moveToNextTab() {

    if (this.selectedIndex != this.maxNumberOfTabs) {


      this.vendorInfoFormData = this.vendorInfoData.returnVendorInfoData();
      if (this.selectedIndex == 0) {

        if (!this.vendorInfoFormData.vendorInfoForm.invalid) {
          this.vendorInfoCodeValue = this.vendorInfoFormData.vendorInformation.vendorCode;
          this.selectedIndex = this.selectedIndex + 1;
          this.receivedVendorInfoData = this.vendorInfoFormData.vendorInformation;
          return;
        } else {

          this.utilityService.showAlert(
            constant.KEY_WARNING,
            constant.ALERT_WARNING_TITLE,
            "Please fill required fields vendor information tab",
          );

        }

      }

      if (this.selectedIndex == 1) {

        this.vendorConnFormValues = this.vendorConnData.returnVendorConnData();

        if (!this.vendorConnFormValues.vendorConnForm.invalid) {
          this.selectedIndex = this.selectedIndex + 1;
          this.receivedVendorConnectionData = this.vendorConnFormValues.vendorConnection
          this.vendorFieldsFormValues = this.vendorFieldsData.returnVendorFieldsData();

          return;
        } else {
          this.utilityService.showAlert(
            constant.KEY_WARNING,
            constant.ALERT_WARNING_TITLE,
            "Please fill required fields vendor connection tab",
          );

        }

      }

      if (this.selectedIndex == 2) {

        this.vendorFieldsFormValues = this.vendorFieldsData.returnVendorFieldsData();

        if (!this.vendorFieldsFormValues.vendorFieldsForm.invalid) {
          this.selectedIndex = this.selectedIndex + 1;
          return;
        } else {

          this.utilityService.showAlert(
            constant.KEY_WARNING,
            constant.ALERT_WARNING_TITLE,
            "Please fill required fields vendor fields tab",
          );

        }

      }
    }



    console.log(this.selectedIndex);
  }

  previousStep() {
    if (this.selectedIndex >= 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
    console.log(this.selectedIndex);
  }

  createFullVendor() {

    this.vendorFieldsFormValues = this.vendorFieldsData.returnVendorFieldsData();

    if (!this.vendorFieldsFormValues.vendorFieldsForm.invalid) {

      // if (this.vendorFieldsFormValues.vendorFields.fileExtension) {
      //   var fileExtResult = this.vendorFieldsFormValues.vendorFields.fileExtension.split(",");

      //   this.vendorFieldsFormValues.vendorFields.fileExtension = [];
      //   fileExtResult.forEach((eachExtension: any) => {
      //     if (eachExtension) {
      //       this.vendorFieldsFormValues.vendorFields.fileExtension.push(eachExtension);
      //     }
      //   });
      // }

      this.passFullVendorData = {
        vendor: this.vendorInfoFormData.vendorInformation,
        vendorConnection: this.vendorConnFormValues.vendorConnection,
        vendorFields: this.vendorFieldsFormValues.vendorFields

      };

      this.vendorService.createFullVendor(this.passFullVendorData).subscribe(response => {

        if (response.result == constant.KEY_SUCCESS) {

          this.utilityService.showAlert(
            constant.KEY_SUCCESS,
            constant.ALERT_ADDED_TITLE,
            "Vendor data added successfully",
          ).subscribe(result => {

            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.vendorMasterDialogRef.close(true)
          });



        } else if (response.result == constant.KEY_FAILURE) {

          this.utilityService.showAlert(
            constant.KEY_ERROR,
            constant.ALERT_FAILED_TITLE,
            response.message,
          );

        }
        else {

          this.utilityService.showAlert(
            constant.KEY_ERROR,
            constant.ALERT_FAILED_TITLE,
            "Error occurred adding the vendor data",
          );

        }

      });
    } else {

      this.utilityService.showAlert(
        constant.KEY_WARNING,
        constant.ALERT_WARNING_TITLE,
        "Please fill required fields vendor fields tab",
      );

    }



  }

  deleteFullVendor() {

    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {

        this.passFullVendorData = {
          NPINo: this.vendorInfoFormData.vendorInformation.NPINo,
          vendorCode: this.vendorInfoFormData.vendorInformation.vendorCode

        };

        this.vendorService.deleteMasterVendor(this.passFullVendorData).subscribe(response => {

          if (response.result == constant.KEY_SUCCESS) {

            this.utilityService.showAlert(
              constant.KEY_SUCCESS,
              constant.ALERT_DELETE_TITLE,
              "Vendor data deleted successfully",
            ).subscribe(result => {

              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.vendorMasterDialogRef.close(true)
            });

          }
          else if(response.result == constant.KEY_FAILURE)
          {
            this.utilityService.showAlert(
              constant.KEY_FAILURE,
              constant.ALERT_FAILED_TITLE,
              "Can not delete vendor data as this vendor has been linked active stores",
            ).subscribe(result => {

            });
          }
          else {

            this.utilityService.showAlert(
              constant.KEY_ERROR,
              constant.ALERT_FAILED_TITLE,
              "Error occurred deleting the vendor data",
            );

          }

        }, error => {

          this.utilityService.showAlert(
            constant.KEY_ERROR,
            constant.ALERT_FAILED_TITLE,
            "Error occurred while deleting the vendor"
          );
        });

      }
    });



  }

  updateFullVendor() {

    //CHECKING THE VENDOR FIELDS VALUES
    this.vendorFieldsFormValues = this.vendorFieldsData.returnVendorFieldsData();

    ////to find out invalid controls
      // const invalid = [];
      // const controls = this.vendorFieldsFormValues.vendorFieldsForm.controls;
      // for (const name in controls) {
      //     if (controls[name].invalid) {
      //         invalid.push(name);
      //     }
      // }
  


    if (this.vendorFieldsFormValues.vendorFieldsForm.invalid) {


      this.utilityService.showAlert(
        constant.KEY_WARNING,
        constant.ALERT_WARNING_TITLE,
        "Please fill required fields vendor fields tab",
      );
      return;

    }

    // if (this.vendorFieldsFormValues.vendorFields.fileExtension) {

    //   let containComma = this.vendorFieldsFormValues.vendorFields.fileExtension.includes(",") ? true : false;

    //   //if the fileextension contains more than 1 type then if confiton true
    //   if (containComma) {

    //     var fileExtResult = this.vendorFieldsFormValues.vendorFields.fileExtension.split(",");

    //     this.vendorFieldsFormValues.vendorFields.fileExtension = [];
    //     fileExtResult.forEach((eachExtension: any) => {
    //       if (eachExtension) {
    //         this.vendorFieldsFormValues.vendorFields.fileExtension.push(eachExtension);
    //       }
    //     });

    //    }
    //   // else {
    //   //   //if the file extension contains only one file type 
    //   //   this.vendorFieldsFormValues.vendorFields.fileExtension = [];
    //   //   // this.vendorFieldsFormValues.vendorFields.fileExtension.push(eachExtension);
    //   // }


    // }

    this.passFullVendorData = {
      vendor: this.vendorInfoFormData.vendorInformation,
      vendorConnection: this.vendorConnFormValues.vendorConnection,
      vendorFields: this.vendorFieldsFormValues.vendorFields

    };

    this.vendorService.updateMasterVendor(this.passFullVendorData).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {

        this.utilityService.showAlert(
          constant.KEY_SUCCESS,
          constant.ALERT_UPDATE_TITLE,
          "Vendor data updated successfully",
        ).subscribe(result => {

          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          this.vendorMasterDialogRef.close(true)
        });

      }
      else {

        this.utilityService.showAlert(
          constant.KEY_ERROR,
          constant.ALERT_FAILED_TITLE,
          "Error occurred updating the vendor data",
        );

      }

    }, error => {

      this.utilityService.showAlert(
        constant.KEY_ERROR,
        constant.ALERT_FAILED_TITLE,
        "Error occurred while updating the vendor"
      );
    });
  }

}
