import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileDownload } from 'src/app/models/FileDownload';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';
import { User } from 'src/app/models/User';
import { userAccess } from 'src/app/models/userAccess';
import { FileDownloadService } from 'src/app/services/FileDownload/file-download.service';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent implements OnInit, AfterViewInit {

  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['NPINo', 'vendorCode','contractName', 'fileName','status', 'fileProcessedDate', 'fileType', 'remark'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<FileDownload> = new MatTableDataSource();

  //=========variable Declaration============
  fileDownload: any = {};
  resultData: any;
  fileDownloadArray: FileDownload[] = [];
  fileDownloadRequest: FileDownloadRequest = new FileDownloadRequest;
  userAccessData!: userAccess;
  currentDate!: any;
  fileStatus: any[] = [];
  constructor(private fileDownloadService: FileDownloadService, private userService: UserService, private utilityService: UtilityService) {
    this.userAccessData = userService.getUsersAccessData();
    this.fileDownload.NPINo = this.userAccessData.NPINo;
    this.fileDownload.pseudoName = this.userAccessData.pseudoName;
  }

  ngAfterViewInit(): void {
    //FETCHING THE FILE DOWNLOAD DATA ON PAGE VIEW INIT
    this.getFileDownloadData();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<FileDownload>(this.fileDownloadArray);
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;

    //SETTING THE DATE IN THE DATE PICKER 
    this.currentDate = new Date();
    var previousMonth = new Date(this.currentDate);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    this.fileDownload.fromDate = previousMonth;
    this.fileDownload.toDate = new Date();
    var fStatus={"fStatus":"Processed"};
    var fStatus1={"fStatus":"Downloaded"};
    var fStatus2={"fStatus":"Error"}
    this.fileStatus.push(fStatus);
    this.fileStatus.push(fStatus1);
    this.fileStatus.push(fStatus2);

  }


  onFromDateChange() {

      if ((Date.parse(this.fileDownload.fromDate) > Date.parse(this.fileDownload.toDate)) && this.fileDownload.toDate) {
        this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than To date").subscribe(result=>{
          this.fileDownload.fromDate="";
        });
        
      } else if (Date.parse(this.fileDownload.fromDate) > Date.parse(this.currentDate)) {
        this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than current date").subscribe(result=>{
          this.fileDownload.fromDate="";
        });
      }
  }

  onToDateChange() {

 
      if ((Date.parse(this.fileDownload.toDate) < Date.parse(this.fileDownload.fromDate)) && this.fileDownload.fromDate) {
        this.utilityService.showAlert("WARNING", "Warning", "To date should be greater than From date").subscribe(result=>{
          this.fileDownload.toDate="";
        });
        
      } else if (Date.parse(this.fileDownload.toDate) > Date.parse(this.currentDate)) {
        this.utilityService.showAlert("WARNING", "Warning", "Please select To date lower than current date").subscribe(result=>{
          this.fileDownload.toDate="";
        });
      }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
      this.getFileDownloadData();
  }



  // ================GET THE FILE DOWNLOAD DATA BASED ON THE PAGINATION=================
  getFileDownloadData() {

    // setting request object 
    this.fileDownloadRequest.NPINo = this.userAccessData.NPINo;
    this.fileDownloadRequest.fromDate = this.fileDownload.fromDate;
    this.fileDownloadRequest.toDate = this.fileDownload.toDate;
    this.fileDownloadRequest.fileType = this.fileDownload.fileType;
    this.fileDownloadRequest.fileStatus=this.fileDownload.fileStatus;
    this.fileDownloadRequest.pagination.pageNumber = this.currentPage + 1;
    this.fileDownloadRequest.pagination.pageSize = this.pageSize;


    this.fileDownloadService.getFileDowloadData(this.fileDownloadRequest).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedFileResponse = JSON.parse(response.data);

        this.dataSource = new MatTableDataSource<FileDownload>(receivedFileResponse.records);

        this.dataSource.paginator = this.paginator!;
        this.dataSource.sort = this.sort!;
        setTimeout(() => {

          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = receivedFileResponse.totalRecords;

        });
        if (receivedFileResponse.records.length < 1) {
          this.utilityService.showAlert(
            "WARNING",
            "Warning",
            "No data found"
          );
        }

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching the file download data"
        );
      }

    });
  }


}
