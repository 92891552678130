<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div class="dialog-headline">
  <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
  <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<!-- <h2 mat-dialog-title style="background-color: rgb(38, 142, 226);">Install Angular</h2> -->
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

  <!-- user form taking values -->

  <form #userModalForm="ngForm" autocomplete="off">

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <!-- THIS INPUT WILL BE SHOWN ON CREATE MODAL -->
        <div *ngIf="!user.mId" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
              [(ngModel)]="user.userName" required>
            <span matSuffix>@{{pseudoName}}</span>


            <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="invalid-feedback">
              Please enter user name
            </mat-error>
          </mat-form-field>
        </div>

        <!-- THIS INPUT WILL BE SHOWN ON UPDATE MODAL -->
        <div *ngIf="user.mId" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

          <mat-form-field>
            <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
              [(ngModel)]="user.userName" required readonly>



            <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="invalid-feedback">
              Please enter user name
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

        </div>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Password" name="password" #password="ngModel" id="password"
              [(ngModel)]="user.password" required [type]="hide ? 'password' : 'text'">

            <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>


          </mat-form-field>

          <mat-password-strength (onStrengthChanged)="onPasswordStrengthChanged($event);" [externalError]="true"
            [min]="8" [max]="10" #passwordComponent [password]="password.value">
          </mat-password-strength>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
              [hideToggle]="showError">
              <mat-expansion-panel-header>
                <mat-panel-description>
                  <mat-icon *ngIf="!panelOpenState">arrow_forward</mat-icon>
                  <mat-icon *ngIf="panelOpenState">arrow_downward</mat-icon>
                  Expand to see errors
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-password-strength-info [passwordComponent]="passwordComponent">
              </mat-password-strength-info>
            </mat-expansion-panel>

          </mat-accordion>
        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Confirm Password" name="confirmPassword" #confirmPassword="ngModel"
              id="confirmPassword" [(ngModel)]="user.confirmPassword" required
              [type]="hideConfirm ? 'password' : 'text'">

            <mat-icon class="pointer" matSuffix (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' :
              'visibility'}}
            </mat-icon>


            <mat-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
              class="invalid-feedback">
              Please enter confirm password
            </mat-error>

            <mat-error *ngIf="(user.confirmPassword!=user.password)" class="invalid-feedback">
              confirm password does not match with password
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <br>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Last Name" name="lName" (keypress)="AllowOnlyCharacter($event)" #lName="ngModel" id="lName" [(ngModel)]="user.lName"
              required>
            <mat-error *ngIf="lName.invalid && (lName.dirty || lName.touched)" class="invalid-feedback">
              Please enter last name
            </mat-error>
          </mat-form-field>


        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="First Name" (keypress)="AllowOnlyCharacter($event)"  name="fName" #fName="ngModel" id="fName" [(ngModel)]="user.fName"
              required>

            <mat-error *ngIf="fName.invalid && (fName.dirty || fName.touched)" class="invalid-feedback">
              Please enter first name
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- THIS DIV WILL BE SHOWN ON UPDATE MODAL -->
    <div fxLayout="row wrap" *ngIf="user.mId">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Last Login Attempt" name="lastLoginAttempt" #lastLoginAttempt="ngModel"
              id="lastLoginAttempt" [(ngModel)]="user.lastLoginAttempt" readonly>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="No Of Attempt" name="noOfAttempt" #noOfAttempt="ngModel" id="noOfAttempt"
              [(ngModel)]="user.noOfAttempt">
          </mat-form-field>

        </div>
      </div>
    </div>

    <!-- THIS DIV WILL BE SHOWN ON UPDATE MODAL -->
    <div fxLayout="row wrap" *ngIf="user.mId">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Modified By" name="modifiedBy" #modifiedBy="ngModel" id="modifiedBy"
              [(ngModel)]="user.modifiedBy" readonly>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Modified On" name="modifiedOn" #modifiedOn="ngModel" id="modifiedOn"
              [(ngModel)]="user.modifiedOn" readonly>
          </mat-form-field>

        </div>
      </div>
    </div>


    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <mat-label>Select Role</mat-label>
            <mat-select #roleName="ngModel" name="roleName" [(ngModel)]="user.roleName" (selectionChange)="roleChange($event.value)">
              <mat-option *ngFor="let eachRole of roleArray" [value]="eachRole.roleName">
                {{ eachRole.roleName}}
              </mat-option>
            </mat-select>


            <mat-error *ngIf="roleName.invalid && (roleName.dirty || roleName.touched)" class="invalid-feedback">
              Please select role
            </mat-error>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

        <!-- THIS DIV WILL BE SHOWN ON UPDATE MODAL -->
        <div *ngIf="user.mId" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Password Changed On" name="passwordChangedOn" #passwordChangedOn="ngModel"
              id="passwordChangedOn" [(ngModel)]="user.passwordChangedOn" readonly>
          </mat-form-field>

        </div>

        <!-- THIS DIV WILL BE SHOWN ON CREATE MODAL -->
        <div *ngIf="!user.mId" style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100"
          fxFlex="100">
          <mat-checkbox #isActive="ngModel" id="isActive" name="isActive" [(ngModel)]="user.isActive">Is Active
          </mat-checkbox>

        </div>
      </div>
    </div>

    <!-- THIS DIV WILL BE SHOWN ON UPDATE MODAL -->
    <div *ngIf="user.mId" fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox #isActive="ngModel" id="isActive" name="isActive" [(ngModel)]="user.isActive">Is Active
          </mat-checkbox>

        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

      </div>
    </div>

    <!-- THIS DIV WILL BE SHOWN ON CREATE MODAL -->
    <div  fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox #changePasswordAtLogin="ngModel" id="changePasswordAtLogin" name="changePasswordAtLogin"
            [(ngModel)]="user.changePasswordAtLogin">Change Password At Login
          </mat-checkbox>

        </div>
      </div>
    </div>


  </form>



</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close class="secondary" matTooltip="Close this dialog">
    <mat-icon>close</mat-icon>Close
  </button>
  <button mat-flat-button *ngIf="!user.mId" color="primary" (click)="createUser();" [disabled]="userModalForm.invalid"
    cdkFocusInitial>Create</button>
  <button mat-flat-button *ngIf="user.mId" color="primary" matTooltip="Update user" (click)="updateUser();"
    [disabled]="userModalForm.invalid || !isPasswordStrong || (user.confirmPassword!=user.password)" cdkFocusInitial>
    <mat-icon>edit</mat-icon>Update
  </button>

  <button mat-flat-button *ngIf="user.mId" color="warn" (click)="deleteUser();" cdkFocusInitial
    matTooltip="Delete user">
    <mat-icon>delete_outline</mat-icon>Delete
  </button>
</mat-dialog-actions>