<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected" group="{{menuitem.state}}">
        <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <span>{{ menuitem.name }}</span> 
            <span fxFlex></span> 
        </a>

        
    </mat-list-item>

</mat-nav-list>