<form #vendorInformationForm="ngForm" autocomplete="off">
    <br>

    <div fxLayout="row wrap" *ngIf="isMMSAdmin && isFromMapping && !isUpdate">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <mat-label>Select NPINo</mat-label>
                    <input type="text" matInput [formControl]="npiControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNPINos"
                        (optionSelected)="setNPINoToModel($event);">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.NPINo}}--{{option.storeName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

            <!-- THIS FIELD WILL BE SHOWN ON THE MASTER PAGE FOR MMS ADMIN BOTH CREATE AND UPDATE PAGE-->
            <div *ngIf="isMMSAdmin  && !isFromMapping" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100"
                fxFlex="100">

                <mat-form-field>
                    <input matInput placeholder="Vendor Name" name="vendorName" #vendorName="ngModel" id="vendorName"
                        [(ngModel)]="vendorInformation.vendorName" required [disabled]="disableFields">

                    <mat-error *ngIf="vendorName.invalid && (vendorName.dirty || vendorName.touched)">
                        Please enter vendor name
                    </mat-error>

                </mat-form-field>


            </div>

            <!-- THIS FIELD WILL BE SHOWN ONLY  UPDATE PAGE FOR MAPPING PAGE-->
            <div *ngIf="isFromMapping && isUpdate" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <input matInput placeholder="Vendor Name" name="vendorName" #vendorName="ngModel" id="vendorName"
                        [(ngModel)]="vendorInformation.vendorName" required [disabled]="disableFields">

                    <mat-error *ngIf="vendorName.invalid && (vendorName.dirty || vendorName.touched)">
                        Please enter vendor name
                    </mat-error>

                </mat-form-field>


            </div>
            <!-- this dropdown option will be shown only to store admin not to mms admin -->
            <div *ngIf="isFromMapping && !isUpdate" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <mat-label>Vendor Name</mat-label>
                    <input type="text" matInput [formControl]="vendorNameControl" [matAutocomplete]="autoVendor"
                        required>
                    <mat-autocomplete #autoVendor="matAutocomplete" [displayWith]="displayVendorName"
                        (optionSelected)="setVendorNameToModel($event);">
                        <mat-option *ngFor="let option of filteredVendorOptions | async" [value]="option">
                            {{option.vendorCode}}--{{option.vendorName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Vendor Code" name="vendorCode" #vendorCode="ngModel" id="vendorCode"
                        [(ngModel)]="vendorInformation.vendorCode" required [disabled]="disableFields">

                    <mat-error *ngIf="vendorCode.invalid && (vendorCode.dirty || vendorCode.touched)"
                        class="invalid-feedback">
                        Please enter vendor code
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Address1" name="address1" #address1="ngModel" id="address1"
                        [(ngModel)]="vendorInformation.address1" required [disabled]="disableFields">



                    <mat-error *ngIf="address1.invalid && (address1.dirty || address1.touched)"
                        class="invalid-feedback">
                        Please enter address1
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Address2" name="address2" #address2="ngModel" id="address2"
                        [(ngModel)]="vendorInformation.address2" [disabled]="disableFields">



                    <mat-error *ngIf="address2.invalid && (address2.dirty || address2.touched)"
                        class="invalid-feedback">
                        Please enter address2
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="City" (keypress)="AllowOnlyCharacter($event)" name="city"
                        #city="ngModel" id="city" [(ngModel)]="vendorInformation.city" required
                        [disabled]="disableFields">



                    <mat-error *ngIf="city.invalid && (city.dirty || city.touched)" class="invalid-feedback">
                        Please enter city
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Select State</mat-label>
                    <mat-select #state="ngModel" name="state" [(ngModel)]="vendorInformation.state" required
                        [disabled]="disableFields">

                        <mat-option *ngFor="let eachState of stateArray" [value]="eachState.abbreviation">
                            {{ eachState.stateName }}
                        </mat-option>
                    </mat-select>


                    <mat-error *ngIf="state.invalid && (state.dirty || state.touched)" class="invalid-feedback">
                        Please select state
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Zip" (keypress)="AllowOnlyNumber($event)" minlength="5" maxlength="5"
                        name="zip" #zip="ngModel" id="zip" [(ngModel)]="vendorInformation.zip" required
                        [disabled]="disableFields">



                    <mat-error *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="invalid-feedback">
                        Please enter zip
                    </mat-error>
                </mat-form-field>


            </div>

            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>

            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput (keypress)="AllowOnlyNumber($event)" mask="(000)000-0000"
                        placeholder="Phone (Office)" minlength="10" name="phoneOff" #phoneOff="ngModel" id="phoneOff"
                        [(ngModel)]="vendorInformation.phoneOff" [disabled]="disableFields">
                </mat-form-field>

            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <input matInput placeholder="Cell No" (keypress)="AllowOnlyNumber($event)" minlength="10"
                        mask="(000)000-0000" name="cellNo" #cellNo="ngModel" id="cellNo"
                        [(ngModel)]="vendorInformation.cellNo" [disabled]="disableFields">
                </mat-form-field>

            </div>
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Fax No" (keypress)="AllowOnlyNumber($event)" minlength="10"
                        mask="(000)000-0000" name="faxNo" #faxNo="ngModel" id="faxNo"
                        [(ngModel)]="vendorInformation.faxNo" [disabled]="disableFields">
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">

                <mat-form-field>
                    <input matInput type="email" placeholder="Email" name="emailAddress" #emailAddress="ngModel"
                        id="emailAddress" [(ngModel)]="vendorInformation.emailAddress" [disabled]="disableFields">
                </mat-form-field>

            </div>
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Website" name="webAddress" #webAddress="ngModel" id="webAddress"
                        [(ngModel)]="vendorInformation.webAddress" [disabled]="disableFields">
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Price Qualifier</mat-label>
                    <mat-select #priceQualifiers="ngModel" name="priceQualifier" [(ngModel)]="vendorInformation.priceQualifier" required>
                        <mat-option *ngFor="let eachQualifier of priceQualifier" [value]="eachQualifier">
                            {{ eachQualifier }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="priceQualifiers.invalid && (priceQualifiers.dirty || priceQualifiers.touched)" class="invalid-feedback">
                        Please select Price Qualifier
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Cost Qualifier</mat-label>
                    <mat-select #costQualifiers="ngModel" name="costQualifier" [(ngModel)]="vendorInformation.costQualifier" required>
                        <mat-option *ngFor="let eachQualifier of costQualifier" [value]="eachQualifier">
                            {{ eachQualifier }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="costQualifiers.invalid && (costQualifiers.dirty || costQualifiers.touched)" class="invalid-feedback">
                        Please select costQualifier
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Sale Qualifier</mat-label>
                    <mat-select #saleQualifiers="ngModel" name="saleQualifier" [(ngModel)]="vendorInformation.saleQualifier" required>
                        <mat-option *ngFor="let eachQualifier of saleQualifier" [value]="eachQualifier">
                            {{ eachQualifier }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="saleQualifiers.invalid && (saleQualifiers.dirty || saleQualifiers.touched)" class="invalid-feedback">
                        Please select saleQualifier
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #isActive="ngModel" id="isActive" name="isActive" 
                        [(ngModel)]="vendorInformation.isActive">Is Active
                    </mat-checkbox>
                </div>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #isEPO="ngModel" id="isEPO" name="isEPO"
                        [(ngModel)]="vendorInformation.isEPO">Is EPO?
                    </mat-checkbox>
                </div>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #salePriceUpdate="ngModel" id="salePriceUpdate" name="salePriceUpdate"
                        [(ngModel)]="vendorInformation.salePriceUpdate">Sale Price Update
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #vendorCostPrice="ngModel" id="vendorCostPrice" name="vendorCostPrice"
                        [(ngModel)]="vendorInformation.vendorCostPrice">Vendor Cost Price
                    </mat-checkbox>
                </div>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #autoClose="ngModel" id="autoClose" name="autoClose"
                        [(ngModel)]="vendorInformation.autoClose">Auto Close
                    </mat-checkbox>
                </div>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #process810="ngModel" id="process810" name="process810"
                        [(ngModel)]="vendorInformation.process810">Processs 810
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #updatePrice832="ngModel" id="updatePrice832" name="updatePrice832"
                        [(ngModel)]="vendorInformation.updatePrice832">Update Price (832)
                    </mat-checkbox>
                </div>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #ackPriceUpdate="ngModel" id="ackPriceUpdate" name="ackPriceUpdate"
                        [(ngModel)]="vendorInformation.ackPriceUpdate">ACK Price Update
                    </mat-checkbox>
                </div>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="26" fxFlex.gt-md="26" fxFlex.gt-xs="100" fxFlex="100">
                <div style="padding-top: 20px;" fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox #reduceSellingPriceWith832File="ngModel" id="reduceSellingPriceWith832File" name="reduceSellingPriceWith832File"
                        [(ngModel)]="vendorInformation.reduceSellingPriceWith832File">Reduce selling price with 832 file
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

</form>