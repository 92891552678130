import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { data } from 'jquery';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { User } from 'src/app/models/User';
import { TokenStorageService } from '../utility/token-storage.service';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private router: Router, private dialogService: MatDialog, private utilityService: UtilityService, private tokenStorageService: TokenStorageService, private idle: Idle,) { }

  loginUser(user: any) {
    return this.httpClient.post<any>(constant.userLoginUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        const receivedDbData: any = JSON.parse(response.data);

        if (response.result == "FAILURE" || response.result == "ERROR") {

          this.utilityService.showAlert("ERROR", "Failed", receivedDbData.Message);
          return false;

         } 
        //else if (data.changePasswordAtLogin) {
        //   const changePasswordRef = this.dialogService.open(ChangePasswordComponent, {
        //     width: '40%',
        //     disableClose: false,
        //     data: { headerTitle: "Change Password at login", userData: data }
        //   });
        //   changePasswordRef.afterClosed().subscribe(result => {

        //     location.reload();

        //   });

        // }
        else {

          if (receivedDbData) {
            // ADDING THE DATA TO SESSION FOR USING IN THE APP

            this.tokenStorageService.saveToken(receivedDbData.token);
            this.tokenStorageService.saveRefreshToken(receivedDbData.user.refreshToken);
            this.tokenStorageService.saveUser(receivedDbData.user);

            localStorage.setItem('NPINo', receivedDbData.user.NPINo);
            localStorage.setItem('changePasswordAtLogin', receivedDbData.user.changePasswordAtLogin);
            localStorage.setItem('userName', receivedDbData.user.userName);
            localStorage.setItem('storeName', receivedDbData.storeDetails.storeName);
            localStorage.setItem('pseudoName', receivedDbData.storeDetails.pseudoName);
            localStorage.setItem('userRights', JSON.stringify(receivedDbData.userRights));

          }
          return response;
        }
      })
    )

  }

  getUsersAccessData(): any {
    let NPINo = localStorage.getItem('NPINo');
    let changePasswordAtLogin = JSON.parse(localStorage.getItem('changePasswordAtLogin') || '');
    let userName = localStorage.getItem('userName');
    let storeName = localStorage.getItem('storeName');
    let pseudoName = localStorage.getItem('pseudoName');
    let userRights = localStorage.getItem('userRights');

    var returnUserData = {
      NPINo: NPINo,
      userName: userName,
      storeName: storeName,
      pseudoName: pseudoName,
      userRights: userRights,
      changePasswordAtLogin: changePasswordAtLogin
    };
    return returnUserData;
  }

  getUsers(user: any) {
    return this.httpClient.post<any>(constant.getUsersUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  getUserByUserName(user: any) {
    return this.httpClient.post<any>(constant.getUserByUserNameUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  createUsers(user: User) {
    return this.httpClient.post<any>(constant.insertUserUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  changeUserPassword(user: User) {
    return this.httpClient.post<any>(constant.changeUserPasswordUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  updateUsers(user: User) {
    return this.httpClient.post<any>(constant.updateUserUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  deleteUsers(user: User) {
    return this.httpClient.post<any>(constant.deleteUserUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }

  logOutUser() {
    //removing the user session and then user will log out 
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  //getting the user from store configurator
  changePasswordAtLogin(user: User) {
    return this.httpClient.post<any>(constant.changeUserPasswordAtLoginUrl, user, constant.httpClientHeader).pipe(
      map((response: any) => {

        return response;
      })
    )

  }


  refreshToken(refToken: string) {
    const params = new URLSearchParams();
    params.set('refToken', refToken);
    return this.httpClient.post<any>(constant.refreshToken, params, constant.httpClientHeader).pipe(
      map((response: any) => {
        const receivedUser: any = JSON.parse(response.data);
        return receivedUser;
      })
    )

  }

  logOutUserFromTimeOut() {
    //removing the user session and then user will log out 
    this.dialogService.closeAll();
    localStorage.clear();
    this.idle.watch();//restart watching the user for idleness
    this.router.navigateByUrl('/login');
    this.utilityService.showAlert("SUCCESS", "Logged Out", "You have been logged out due to inactivity");
  }

}
