<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/1.jpg" alt="user"
        class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item>
        <mat-icon color="primary">account_box</mat-icon> {{userAccessData.userName}}

    </button>
    <button mat-menu-item (click)="editLoggedinUserProfile()">
        <mat-icon color="primary">mode_edit</mat-icon> Edit Profile

    </button>
    <button mat-menu-item (click)="forgotPassword()">
        <mat-icon color="primary">mode_edit</mat-icon> Forgot Password
    </button>
    <button mat-menu-item (click)="logOutUser()">
        <mat-icon color="primary">exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>