<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div class="dialog-headline">
    <mat-label style="float:left;">{{data.title}}</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <mat-tab-group color="primary" mat-stretch-tabs [selectedIndex]="selectedIndex" animationDuration="0ms">
        <mat-tab disabled label="Vendor Information">

            <app-vendor-information [receivedVendorInfo]="receivedVendorInfoData" [disableFields]="false"
                [isUpdate]="isUpdate" #vendorInformation></app-vendor-information>

        </mat-tab>

        <mat-tab disabled label="Vendor Connection">

            <app-vendor-connection [receivedVendorConn]="dbVendorConnection" [receivedVendorCode]="vendorInfoCodeValue"
                [disableFields]="false" #vendorConnection>
            </app-vendor-connection>

        </mat-tab>

        <mat-tab disabled label="Vendor Fields">

            <app-vendor-fields [receivedVendorFields]="dbVendorFields" [receivedVendorCode]="vendorInfoCodeValue"
                [disableFields]="false" #vendorFields>
            </app-vendor-fields>


        </mat-tab>

    </mat-tab-group>

</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end">

    <button mat-stroked-button mat-dialog-close class="secondary">
        <mat-icon>close</mat-icon>Close
    </button>

    <button mat-stroked-button class="secondary" *ngIf="selectedIndex > 0" (click)="previousStep()">
        <mat-icon>arrow_back</mat-icon>Back
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex!=maxNumberOfTabs" (click)="moveToNextTab()">Next
        <mat-icon>arrow_forward</mat-icon>
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex==2 && !isUpdate" (click)="createFullVendor();">
        <mat-icon>add</mat-icon>Create
    </button>

    <button mat-flat-button color="primary" *ngIf="selectedIndex==2 && isUpdate" (click)="updateFullVendor();">
        <mat-icon>mode_edit</mat-icon>Update
    </button>

    <button mat-flat-button *ngIf="selectedIndex==2 && isUpdate" color="warn" (click)="deleteFullVendor();"
        matTooltip="Delete {{receivedVendorInfoData.vendorCode}}">
        <mat-icon>delete_outline</mat-icon>Delete
    </button>

</mat-dialog-actions>