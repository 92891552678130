import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../ediComponents/user/changePassword/change-password.component';
import { userAccess } from '../models/userAccess';
import { UserService } from '../services/User/user.service';

@Component({
  selector: 'app-change-password-at-login',
  templateUrl: './change-password-at-login.component.html',
  styleUrls: ['./change-password-at-login.component.css']
})
export class ChangePasswordAtLoginComponent implements OnInit {

  userAccessData!: userAccess;
  constructor(private dialogService: MatDialog,private userService:UserService) {
    this.userAccessData = this.userService.getUsersAccessData();
  }

  ngOnInit(): void {

    this.dialogService.open(ChangePasswordComponent, {
      disableClose: true,
      data: { NPINo: this.userAccessData.NPINo,changePasswordAtLogin:this.userAccessData.changePasswordAtLogin, userName: this.userAccessData.userName, headerTitle: "Change Password" }
    });
  }

}
