import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/models/User';
import { userAccess } from 'src/app/models/userAccess';
import { Vendor } from 'src/app/models/Vendor';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { VendorFields } from 'src/app/models/VendorFields';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { VendorInformationComponent } from '../vendorComponents/vendor-information/vendor-information.component';
import { VendorMasterDialogComponent } from '../vendorMaster/vendor-master-dialog/vendor-master-dialog.component';
import { VendorMapDialogComponent } from './vendor-map-dialog/vendor-map-dialog.component';
import { ContractModalComponent } from './vendorContract/contract-modal.component';

@Component({
  selector: 'app-vendor-mapping',
  templateUrl: './vendor-mapping.component.html',
  styleUrls: ['./vendor-mapping.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VendorMappingComponent implements OnInit {

  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['Expand/Collapse', 'NPINo', 'vendorCode', 'vendorName', 'address1', 'city', 'state', 'zip', 'isActive', 'Action'];
  innerDisplayedColumns = ['contractName', 'contractUsername', 'contractPassword', 'contractIsaInterchangeSenderId', 'contractAppSenderCode'
    , 'contractIdentificationCodeBy', 'contractOutboundDirName', 'contractInboundDirName', 'contractPriceFileFormat', 'contractAckFileFormat', 'contractFileFormat810', 'Edit'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('outerSort', { static: true }) outersort!: MatSort;
  @ViewChildren('innerSort') innerSort!: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables!: QueryList<MatTable<ContractDetail>>;
  //  sort: MatSort | undefined;
  // dataSource: MatTableDataSource<Vendor> = new MatTableDataSource();
  //=========variable Declaration============
  hideOldPwd: boolean = true;
  VendorContractDataSource!: MatTableDataSource<VendorWithContract>;
  dbVendorArray: VendorWithContract[] = [];
  vendorDetails: any[] = [];
  expandedElement!: VendorWithContract | null;
  showNested: boolean = false;
  resultData: any;
  dbVendorConnection!: VendorConnection;
  dbVendorFields!: VendorFields;
  dbVendorInfo!: Vendor;
  userAccessData!: userAccess;
  vendorArray: VendorWithContract[] = [];
  passVendorData: any = {}
  vendor: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false

  };
  contract: any = {
    contractName: '',
    contractUsername: '',
    contractPassword: '',
    contractIsaInterchangeSenderId: '',
    contractAppSenderCode: '',
    contractIdentificationCodeBy: '',
    contractOutboundDirName: '',
    contractInboundDirName: '',
    contractPriceFileFormat: '',
    contractAckFileFormat: '',
    contractFileFormat810: '',
  };
  vendorWithContract: VendorWithContracts = {
    vendor: this.vendor,
    contractDetail: this.contract,
  };
  constructor(public dialogService: MatDialog, private changeDetector: ChangeDetectorRef, private vendorService: VendorService, private userService: UserService, private utilityService: UtilityService) {

    this.userAccessData = userService.getUsersAccessData();
    if (this.userAccessData.NPINo != "0000000000")
      this.vendor.NPINo = this.userAccessData.NPINo;

    this.vendor.pseudoName = this.userAccessData.pseudoName;
  }

  ngOnInit(): void {
    //for loading the vendors on page  load
    this.getVendors();

    this.loadVendorsByNPI();

  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.VendorContractDataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    // this.getMatUser();
  }

  openMappingDialog() {

    const mapDialogRef = this.dialogService.open(VendorMapDialogComponent, {
      width: '50%',
      disableClose: true,
      data: {
        isUpdate: false,
        isCreate: true
      }
    });

    mapDialogRef.afterClosed().subscribe(result => {
      //NOT USING THE RESULT BECAUSE IF THE CLICKS ON BACKGROUND THEN RESULT IS UNDEFINED 
      //for loading the vendors on page  load
      if (result == true) {
        this.getVendors();

        this.loadVendorsByNPI();
      }
    });
  }

  openVendorMappingUpdateDialog(receivedVendor: Vendor) {

    this.vendorService.getVendorConnectionAndField(receivedVendor).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedVendorResponse = JSON.parse(response.data);
        this.dbVendorConnection = receivedVendorResponse.vendorConnection;
        this.dbVendorFields = receivedVendorResponse.vendorFields;

        //OPENING THE MODAL AFTER GETTING THE REQUIRED DATA FROM DB
        const mapDialogRef = this.dialogService.open(VendorMapDialogComponent, {
          width: '50%',
          disableClose: true,
          data: {
            isUpdate: true,
            isCreate: false,
            receivedVendorInfo: receivedVendor,
            dbVendorFields: this.dbVendorFields,
            dbVendorConnection: this.dbVendorConnection,
          }
        });

        mapDialogRef.afterClosed().subscribe(result => {

          //for loading the vendors on page  load
          if (result == true) {
            this.getVendors();

            this.loadVendorsByNPI();
          }
        });

      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching vendor data"
        );
      }
    });
  }

  //==========getting the vendor ===============
  getVendors() {

    this.vendorService.getVendorWithContractData(this.vendor).subscribe(response => {

      if (response.result == "SUCCESS") {
        this.vendorDetails = JSON.parse(response.data);

        if (this.vendorDetails.length > 0)
          this.mapModelForContractTable();

        // this.VendorContractDataSource = new MatTableDataSource<VendorWithContract>(this.vendorDetails);
        // this.VendorContractDataSource.paginator = this.paginator!;
        // this.VendorContractDataSource.sort = this.sort!;
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching vendor data"
        );
      }

    });
  }

  mapModelForContractTable() {
    this.dbVendorArray = [];

    this.vendorDetails.forEach(eachContract => {
      if (eachContract.contractDetail && Array.isArray(eachContract.contractDetail)) {
        this.dbVendorArray = [...this.dbVendorArray, { ...eachContract, contractDetail: new MatTableDataSource(eachContract.contractDetail) }];
      } else {
        delete eachContract.contractDetail;
        this.dbVendorArray = [...this.dbVendorArray, eachContract];
      }
    });

    this.VendorContractDataSource = new MatTableDataSource(this.dbVendorArray);
    this.VendorContractDataSource.paginator = this.paginator!;
    this.VendorContractDataSource.sort = this.outersort;

  }

  loadVendorsByNPI() {

    if (this.userAccessData.NPINo != "0000000000") {

      this.passVendorData = {
        NPINo: this.userAccessData.NPINo
      };
    } else {

      //HERE PASSING THE BLANK NPI TO GET THE LIST OF ALL THE VENDORS
      this.passVendorData = {
        NPINo: ''
      };
    }


    this.vendorService.getVendorWithContractData(this.passVendorData).subscribe(response => {

      if (response.result == "SUCCESS") {
        this.vendorDetails = JSON.parse(response.data);

        if (this.vendorDetails.length > 0)
          this.mapModelForContractTable();
        // this.VendorContractDataSource = new MatTableDataSource<VendorWithContract>(this.vendorDetails);
        // this.VendorContractDataSource.paginator = this.paginator!;
        // this.VendorContractDataSource.sort = this.sort!;

        this.vendorArray = this.vendorDetails;
        let distinctVendor = [
          ...new Map(this.vendorArray.map((item) => [item.vendor["vendorCode"], item])).values(),
        ];

        this.vendorArray = distinctVendor;


      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error while fetching vendor data"
        );
      }

    });
  }

  toggleRow(element: VendorWithContract) {
    element.contractDetail && (element.contractDetail as MatTableDataSource<ContractDetail>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.changeDetector.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<ContractDetail>).sort = this.innerSort.toArray()[index]);
  }
  onEdit(item: any) {
    item.isEdit = true;
  }
  // onUpdate(item: any) {
  //   item.isEdit = false;
  // }
  onCancel(item: any) {
    item.isEdit = false;
  }
  AddContract(NPI: string, vendorCode: string) {
    const contractModalRef = this.dialogService.open(ContractModalComponent, {
      width: '30%',
      disableClose: false,
      data: { NPI: NPI, vendorCode: vendorCode }
    });

    contractModalRef.afterClosed().subscribe(result => {
      //getting tax on load for showing in the table after creating the user
      if (result == true) {
        this.getVendors();
      }
    });

  }

  onUpdate(element: any) {
    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.vendor.NPINo = element.NPINO;
    this.vendorWithContract.vendor.vendorCode = element.vendorCode;
    // this.vendorWithContract.contractDetail!.NPINO = element.NPINO;
    // this.vendorWithContract.contractDetail!.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.contractName = element.contractName;
    this.vendorWithContract.contractDetail!.contractUsername = element.contractUsername;
    this.vendorWithContract.contractDetail!.contractPassword = element.contractPassword;
    this.vendorWithContract.contractDetail!.contractIsaInterchangeSenderId = element.contractIsaInterchangeSenderId;
    this.vendorWithContract.contractDetail!.contractAppSenderCode = element.contractAppSenderCode;
    this.vendorWithContract.contractDetail!.contractIdentificationCodeBy = element.contractIdentificationCodeBy;
    this.vendorWithContract.contractDetail!.contractOutboundDirName = element.contractOutboundDirName;
    this.vendorWithContract.contractDetail!.contractInboundDirName = element.contractInboundDirName;
    this.vendorWithContract.contractDetail!.contractPriceFileFormat = element.contractPriceFileFormat;
    this.vendorWithContract.contractDetail!.contractAckFileFormat = element.contractAckFileFormat;
    this.vendorWithContract.contractDetail!.contractFileFormat810 = element.contractFileFormat810;

    this.vendorService.saveVendorWithContractData(this.vendorWithContract).subscribe(response => {

      if (response == null || response.result != "SUCCESS") {
        this.utilityService.showAlert('ERROR', 'Failed', "Error occurred while updating the contract");
      }
      else {
        this.utilityService.showAlert('SUCCESS', 'Updated', "Contract updated successfully").subscribe((result: any) => {
          element.isEdit = false;
          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
        });
      }
    });
  }

  onDelete(element: any) {
    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.vendor.NPINo = element.NPINO;
    this.vendorWithContract.vendor.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.NPINO = element.NPINO;
    this.vendorWithContract.contractDetail!.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.contractName = element.contractName;

    this.vendorService.deleteVendorContract(this.vendorWithContract).subscribe(response => {

      if (response.result == "SUCCESS") {

        this.utilityService.showAlert('SUCCESS', 'Deleted', "Contract deleted successfully").subscribe((result: any) => {
          element.isEdit = false;
          this.loadVendorsByNPI();
          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
        });
      }
      else {
        this.utilityService.showAlert('ERROR', 'Failed', "Error occurred while deleting the contract");
      }
    });
  }

  testVendorFtpSftpConnection(element: any) {

    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.vendor.NPINo = element.NPINO;
    this.vendorWithContract.vendor.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.contractURL = element.contractURL;
    this.vendorWithContract.contractDetail!.contractPort = element.contractPort;
    this.vendorWithContract.contractDetail!.contractConnectionType = element.contractConnectionType;
    this.vendorWithContract.contractDetail!.contractInboundDir = element.contractInboundDir;
    this.vendorWithContract.contractDetail!.contractOutboundDir = element.contractOutboundDir;
    this.vendorWithContract.contractDetail!.contractName = element.contractName;
    this.vendorWithContract.contractDetail!.contractUsername = element.contractUsername;
    this.vendorWithContract.contractDetail!.contractPassword = element.contractPassword;
    this.vendorWithContract.contractDetail!.contractIsaInterchangeSenderId = element.contractIsaInterchangeSenderId;
    this.vendorWithContract.contractDetail!.contractAppSenderCode = element.contractAppSenderCode;
    this.vendorWithContract.contractDetail!.contractIdentificationCodeBy = element.contractIdentificationCodeBy;
    this.vendorWithContract.contractDetail!.contractOutboundDirName = element.contractOutboundDirName;
    this.vendorWithContract.contractDetail!.contractInboundDirName = element.contractInboundDirName;
    this.vendorWithContract.contractDetail!.contractPriceFileFormat = element.contractPriceFileFormat;
    this.vendorWithContract.contractDetail!.contractAckFileFormat = element.contractAckFileFormat;
    this.vendorWithContract.contractDetail!.contractFileFormat810 = element.contractFileFormat810;

    this.vendorService.testVendorFtpConnection(this.vendorWithContract).subscribe(response => {

      var receivedConnectionResponse = JSON.parse(response.data);
      var message = "";
      if (response.result == "SUCCESS") {
        if (receivedConnectionResponse.isFtpConnected == true) {

          if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == true) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory exists.\n" +
              "Outbound directory exists.\n";
          } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == true) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory does not exist.\n" +
              "Outbound directory exists.\n";
          } else if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == false) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory  exists.\n" +
              "Outbound directory does not exist.\n";
          } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == false) {

            message = "FTP/SFTP connection tested successfully.\n" +
              "Inbound directory does not exist.\n" +
              "Outbound directory does not exist. \n";
          }
        } else if (receivedConnectionResponse.isFtpConnected == false) {

          this.utilityService.showAlert(
            "ERROR",
            "Failed",
            "FTP connection failed"
          );
        }

        this.utilityService.showAlert(
          "SUCCESS",
          "Info",
          message
        );
      }
      else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while testing the connection"
        );
      }

    }, error => {

      if (error.error.result == "ERROR") {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "User name or password is wrong"
        );

      } else {

        this.utilityService.showAlert(
          "ERROR",
          "Failed",
          "Error occurred while testing the connection"
        );
      }
    });

  }
}

export interface VendorWithContract {
  vendor: Vendor;
  contractDetail?: ContractDetail[] | MatTableDataSource<ContractDetail>;
}
//used just for sending data while saving vendor contracts
export interface VendorWithContracts {
  vendor: Vendor;
  contractDetail?: ContractDetail;
}

export interface ContractDetail {

  contractName: string
  contractUsername: string
  contractPassword: string
  contractIsaInterchangeSenderId: string
  contractAppSenderCode: string
  contractIdentificationCodeBy: string
  contractOutboundDirName: string;
  contractInboundDirName: string;
  contractPriceFileFormat: string
  contractAckFileFormat: string
  contractFileFormat810: string
  NPINO: string
  vendorCode: string
  contractURL: string
  contractConnectionType: string
  contractPort: string
  contractInboundDir: string
  contractOutboundDir: string
  isEdit: false
}