import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ChangePasswordComponent } from '../ediComponents/user/changePassword/change-password.component';
import { userAccess } from '../models/userAccess';
import { UserService } from '../services/User/user.service';

@Injectable({
  providedIn: 'root'
})
export class FileParsingErrorGuard implements CanActivate {

  userAccessData!: userAccess;
  constructor(private routerService: Router,private userService:UserService,private dialogService: MatDialog) {
    this.userAccessData = userService.getUsersAccessData();
   }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var rightAccess =JSON.parse(this.userAccessData.userRights);

    if (JSON.parse(localStorage.getItem('changePasswordAtLogin') || '')!= false) {
      this.routerService.navigateByUrl('/changePasswordAtLogin');

      return false;
    }else if (rightAccess?.webRights.fileParsingError != false) {
      return true;
    } else if (!rightAccess?.webRights.fileParsingError) {
      
    //  this.routerService.navigateByUrl('/unAuthorisedPage');
      return true;

    } else  {
      this.routerService.navigateByUrl('/pageNotFound');
      return false;

    }
  }

}
