import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { FileDownload } from 'src/app/models/FileDownload';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private httpClient: HttpClient) { }


  getFileDowloadData(fileData:FileDownloadRequest){
    return this.httpClient.post<any>(constant.getfileDownloadUrl, fileData, constant.httpClientHeader);
   
  }
}
