import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/models/User';
import { userAccess } from 'src/app/models/userAccess';
import { Vendor } from 'src/app/models/Vendor';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { VendorFields } from 'src/app/models/VendorFields';
import { UserService } from 'src/app/services/User/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { VendorMapDialogComponent } from '../vendorMapping/vendor-map-dialog/vendor-map-dialog.component';
import { VendorMasterDialogComponent } from './vendor-master-dialog/vendor-master-dialog.component';
import { constant } from '../../constant/constant.dev';

@Component({
  selector: 'app-vendor-master',
  templateUrl: './vendor-master.component.html',
  styleUrls: ['./vendor-master.component.css']
})
export class VendorMasterComponent implements OnInit {


  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['vendorCode', 'vendorName', 'address1', 'city', 'state', 'zip', 'isActive', 'Action'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<Vendor> = new MatTableDataSource();

  //=========variable Declaration============
  fileDownload: any = {};
  resultData: any;
  masterVendorArray: any;
  dbVendorConnection!: VendorConnection;
  dbVendorFields!: VendorFields;
  userAccessData!: userAccess;
  vendorMasterArray: Vendor[] = [];
  vendorMaster: Vendor = {
    mId: '',
    NPINo: '',
    pseudoName: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    priceQualifier:'',
    costQualifier:'',
    saleQualifier:'',
    isEPO:false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File:false
  };

  constructor(public dialogService: MatDialog, private vendorService: VendorService, private userService: UserService, private utilityService: UtilityService) {
    this.userAccessData = userService.getUsersAccessData();
    this.vendorMaster.NPINo = this.userAccessData.NPINo;
    this.vendorMaster.pseudoName = this.userAccessData.pseudoName;
  }

  ngOnInit(): void {
    this.loadMasterVendor()//for loading the dropdown
    this.getMasterVendor();//for loading the table
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    // this.getMatUser();
  }

  openVendorMasterCreateDialog() {

    const vendorMasterDialogRef = this.dialogService.open(VendorMasterDialogComponent, {
      width: '50%',
      disableClose: true,
      data: { title: "Create Vendor", isUpdate: false }
    });

    vendorMasterDialogRef.afterClosed().subscribe(result => {

      //for loading the vendors on page  load
      if (result == true) {
        this.loadMasterVendor()//for loading the dropdown
        this.getMasterVendor();//for loading the table
      }

    });
  }

  //==========opening the vendor master dialog view  ===============
  openVendorMasterUpdateDialog(receivedVendorInfo: Vendor) {
    let vendor = {
      vendorCode: receivedVendorInfo.vendorCode
    };

    this.vendorService.getMasterVendorConnectionAndField(vendor).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {
        var receivedVendorResponse = JSON.parse(response.data);
        this.dbVendorConnection = receivedVendorResponse.vendorConnection;
        this.dbVendorFields = receivedVendorResponse.vendorFields;
        // this.dbVendorFields.fileExtension = this.dbVendorFields.fileExtension.toString();

        //OPENING THE MODAL AFTER GETTING THE REQUIRED DATA FROM DB
        const vendorMasterDialogRef = this.dialogService.open(VendorMasterDialogComponent, {
          width: '50%',
          disableClose: true,
          data: {
            title: "Update Vendor",
            isUpdate: true,
            receivedVendorInfo: receivedVendorInfo,
            dbVendorFields: this.dbVendorFields,
            dbVendorConnection: this.dbVendorConnection,

          }
        });

        vendorMasterDialogRef.afterClosed().subscribe(result => {
          if (result == true) {
            //for loading the vendors on page  load
            this.loadMasterVendor()//for loading the dropdown
            this.getMasterVendor();//for loading the table
          }

        });

      }
      else {

        this.utilityService.showAlert(
          constant.KEY_ERROR,
          "Failed",
          "Error while fetching master vendor data"
        );
      }

    });

  }

  //================Delete vendor==================
  deleteVendorMaster(vendorData: any) {

    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {

        let passFullVendorData = {
          NPINo: vendorData.NPINo,
          vendorCode: vendorData.vendorCode

        };

        this.vendorService.deleteMasterVendor(passFullVendorData).subscribe(response => {

          if (response.result == constant.KEY_SUCCESS) {

            this.utilityService.showAlert(
              constant.KEY_SUCCESS,
              "Deleted",
              "Vendor data deleted successfully",
            ).subscribe(result => {

              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.closeAll();
              this.loadMasterVendor()//for loading the dropdown
              this.getMasterVendor();//for loading the table
            });
          }
          else if(response.result == constant.KEY_FAILURE)
          {
            this.utilityService.showAlert(
              constant.KEY_FAILURE,
              "Failed",
              "Can not delete vendor data as this vendor has been linked active stores",
            ).subscribe(result => {

              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.closeAll();
            });
          }
          else {

            this.utilityService.showAlert(
              constant.KEY_ERROR,
              "Failed",
              "Error occurred deleting the vendor data",
            );

          }

        }, error => {

          this.utilityService.showAlert(
            constant.KEY_ERROR,
            "Failed",
            "Error occurred while deleting the vendor"
          );
        });

      }
    });



  }


  //==========getting the master vendor ===============
  getMasterVendor() {

    let vendor = {
      NPINo: '',
      vendorCode: this.vendorMaster.vendorCode ? this.vendorMaster.vendorCode : ""
    };

    this.vendorService.getMasterVendor(vendor).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {
        var receivedVendorResponse = JSON.parse(response.data);

        this.dataSource = new MatTableDataSource<Vendor>(receivedVendorResponse);
        this.masterVendorArray = receivedVendorResponse;
        this.dataSource.paginator = this.paginator!;
        this.dataSource.sort = this.sort!;

      }
      else {

        this.utilityService.showAlert(
          constant.KEY_ERROR,
          constant.ALERT_FAILED_TITLE,
          "Error while fetching master vendor data"
        );
      }

    });
  }


  //==========loading  the master vendor in the dropdown ===============
  loadMasterVendor() {

    let vendor = {
      NPINo: ''
    };

    this.vendorService.getMasterVendor(vendor).subscribe(response => {

      if (response.result == constant.KEY_SUCCESS) {
        var receivedVendorResponse = JSON.parse(response.data);

        this.vendorMasterArray = receivedVendorResponse;
      }
      else {

        this.utilityService.showAlert(
          constant.KEY_ERROR,
          constant.ALERT_FAILED_TITLE,
          "Error while fetching master vendor data"
        );
      }

    });
  }

}
